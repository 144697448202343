import { useQuery } from "react-query"
import { fetchProject } from "../../data"
import { useStoreActions, useStoreState } from "../../state/hooks"

/**
 * Hook that wraps a query for fetching the project files
 * @returns
 */
const useProject = () => {
  const calculateWorker = useStoreState((state) => state.workers.calculateWorker)
  const importProject = useStoreActions((actions) => actions.project.fetchProject)

  const query = useQuery(
    "project",
    async () => {
      const project = await fetchProject()

      // Update the store to use the fetched project
      importProject(project)

      // The calculate worker stores its own copy of rules.
      calculateWorker.setRules(project.rules)

      return project
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  return query
}

export default useProject
