import { DataCollection } from "./DataCollection"

export type ConstraintValue = string
export type ConstraintEntry = {
  user?: ConstraintValue
  server?: DataCollection
}

export const getUserServerValue = (entry: ConstraintEntry, includeUserData?: boolean): string => {
  const userData = entry?.user

  let serverData: string | undefined

  if (entry?.server !== undefined) {
    switch (entry.server.type) {
      case "numerical":
        serverData = entry.server.value.toFixed(2)
        break
      case "nominal":
      case "ordinal":
        serverData = entry.server.value
    }
  }

  if (includeUserData === undefined || includeUserData) return userData ?? serverData ?? ""
  return serverData ?? ""
}

export const getSource = (entry: ConstraintEntry, includeUserData?: boolean): string => {
  if ((includeUserData === undefined || includeUserData) && entry?.user) return "User"
  else if (entry?.server) return "Tool"
  else return "N/A"
}

export const getMetadata = (data: ConstraintEntry): Record<string, string> => {
  if (data?.user) return {}
  else if (data?.server && data.server.stats) {
    switch (data.server.type) {
      case "nominal": {
        const { stats } = data.server
        //const mode = stats.mode
        const relFreq = (stats.relFreq * 100).toFixed(0)
        const variability = stats.variability
        return {
          freq: relFreq + "%",
          variabiltiy: variability,
        }
      }
      case "ordinal": {
        const { stats } = data.server
        const mean = stats.mean.toFixed(2)
        const std = stats.std.toFixed(2)
        const variability = stats?.variability
        return {
          mean: mean,
          std: std,
          variabiltiy: variability,
        }
      }
      case "numerical": {
        const { stats } = data.server
        const mean = stats.mean.toFixed(2)
        const std = stats.std.toFixed(2)
        const variability = stats.variability
        return {
          mean: mean,
          std: std,
          variabiltiy: variability,
        }
      }
    }
  } else return {}
}
