import React, { useState } from "react"
import { Button, Box } from "@mui/material"
import { Alert } from "@mui/material"
import { useStoreActions, useStoreState } from "../../../state/hooks"
import GBRMap from "./GBRMap/GBRMap"
import AlertManager from "../../common/AlertManager"
import strings from "../../../strings"

const NOT_STARTED_HELP_MESSAGE = strings.MAP_ELEMENTS.NOT_STARTED_HELP_MESSAGE
const IN_PROGRESS_HELP_MESSAGE = strings.MAP_ELEMENTS.IN_PROGRESS_HELP_MESSAGE
const FREEHAND_POLYGON_HELP_MESSAGE = strings.MAP_ELEMENTS.FREEHAND_POLYGON_HELP_MESSAGE

const HelpAlert = (props: { freehand: boolean }) => {
  const selectionState = useStoreState((state) => state.session.map.selectionState)
  if (selectionState === "completed") return <> </>
  return (
    <Alert severity="info">
      {props.freehand
        ? FREEHAND_POLYGON_HELP_MESSAGE
        : selectionState === "not started"
        ? NOT_STARTED_HELP_MESSAGE
        : IN_PROGRESS_HELP_MESSAGE}
    </Alert>
  )
}

const FrameworkAlert = (props: { site: string }) => {
  return <Alert severity="success">{strings.MAP_ELEMENTS.ACTIVE_FRAMEWORK_MESSAGE + props.site}</Alert>
}

const MapSelect = () => {
  const [undo, setUndo] = useState(0)
  const site = useStoreState((state) => state.session.map.framework)
  const alerts = useStoreState((state) => state.alert.mapAlerts)

  const clearAlertsBySection = useStoreActions((actions) => actions.alert.clearAlertsBySection)

  const handleUndoClick = () => {
    setUndo((i) => i + 1)
    clearAlertsBySection("map")
  }

  return (
    <>
      {site === null && alerts.length === 0 && <HelpAlert freehand={false} />}
      {site && alerts.length === 0 && <FrameworkAlert site={site} />}
      <AlertManager alerts={alerts} />

      <GBRMap undo={undo} />
      <Box paddingY={1} display="flex" justifyContent="flex-end">
        <Button onClick={handleUndoClick} variant="contained" color="primary">
          {strings.GENERAL_ELEMENTS.UNDO_BUTTON}
        </Button>
      </Box>
    </>
  )
}

export default React.memo(MapSelect)
