import { Box, Button, Card, Divider, List, ListItem, ListItemText, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import RemoveIcon from "@mui/icons-material/Remove"
import { useState } from "react"
import { useIsFetching } from "react-query"

import { useStoreActions, useStoreState } from "../../../state/hooks"
import SearchInput from "../../common/SearchInput"

const PREFIX = "SelectedLimitations"

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  list: `${PREFIX}-list`,
  searchContainer: `${PREFIX}-searchContainer`,
}

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 400,
  },

  [`& .${classes.header}`]: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  [`& .${classes.list}`]: {
    width: 350,
    height: 400,
    overflow: "auto",
  },

  [`& .${classes.searchContainer}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}))

type SelectedListItemProps = {
  ruleCode: string
  removeable: boolean
}
export const SelectedListItem = (props: SelectedListItemProps) => {
  const { ruleCode, removeable } = props
  const name = useStoreState((state) => state.project.rules.get(ruleCode)?.name) ?? ""
  const remove = useStoreActions((state) => state.session.rules.remove)

  const handleRemove = removeable ? () => remove({ id: ruleCode }) : () => {}

  const isFetching = useIsFetching()

  const labelId = `transfer-list-item-${ruleCode}-label`

  return (
    <ListItem button onClick={handleRemove} disabled={isFetching > 0 || !removeable}>
      <ListItemText id={labelId} primary={name} />
      {removeable && <RemoveIcon />}
    </ListItem>
  )
}

type Props = {
  unremoveableRules: Set<string>
}

const SelectedLimitations = (props: Props) => {
  const { unremoveableRules } = props

  const [searchTerm, setSearchTerm] = useState("")

  const rules = useStoreState((state) => state.project.rules)
  const ruleCodes = useStoreState((state) => state.project.rulesAsList)
  const selectedRules = useStoreState((state) => state.session.rules.selectedRules)
  const remove = useStoreActions((state) => state.session.rules.remove)

  const selected = ruleCodes.filter((rule) => selectedRules.has(rule))

  const handleRemoveAll = () => selected.filter((id) => !unremoveableRules.has(id)).forEach((id) => remove({ id: id }))

  const filtered = selected.filter((code) => {
    const rule = rules.get(code)

    if (rule === undefined) return false
    if (searchTerm.length === 0) return true

    const { name, id, description } = rule
    const searchDomain = [name, id, description ?? ""]

    const searchTermLowered = searchTerm.toLowerCase()
    return searchDomain.filter((item) => item.toLowerCase().includes(searchTermLowered)).length > 0
  })

  return (
    <StyledCard className="root" variant="outlined">
      <Box className={classes.header}>
        <Typography display="inline" variant="subtitle1">
          Selected Limitations
        </Typography>
        <Button onClick={handleRemoveAll}>Unselect All</Button>
      </Box>
      <Box className={classes.searchContainer}>
        <SearchInput value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
      </Box>
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {filtered.map((ruleCode, idx) => (
          <SelectedListItem key={idx} ruleCode={ruleCode} removeable={!unremoveableRules.has(ruleCode)} />
        ))}
      </List>
    </StyledCard>
  )
}

export default SelectedLimitations
