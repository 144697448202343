import GeoJSON from "ol/format/GeoJSON"
import { Vector } from "ol/source"

import { useQuery } from "react-query"
import { fetchGBRRegions } from "../../data"

/**
 * Hook that wraps useQuery to fetch gbr regions
 * @returns a UseQueryResult for a Vector<Geometry> representing gbr regions
 */
export const useGbrRegions = () => {
  const query = useQuery(
    "gbr_regions",
    async () => {
      const features = await fetchGBRRegions()
      // Parse the gbr regions into a vector source
      return new Vector({ features: new GeoJSON().readFeatures(features) })
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  )

  return query
}

export default useGbrRegions
