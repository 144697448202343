import { pipe } from "fp-ts/lib/function"
import * as E from "fp-ts/lib/Either"
import * as R from "fp-ts/lib/Record"
import { ConstraintValue } from "./ConstraintEntry"
import { DataCollection, mkDataCollection } from "./DataCollection"
import { Constraint, Reliability } from "./types"

/**
 * Data sampled at a certain resolution.
 */
export type SampledData = {
  // sampling resolution
  resolution_m: number
  // the data points
  samples: SamplePoint[]
}

export type SamplePoint = {
  // the geometry representing the sample in GeoJSON.
  // usually a Point or a Polygon
  geom?: {
    type: "Point"
    coordinates: number[]
  }
  // the attributes associated with this sample
  values: Record<string, ConstraintValue | null>
  // the reliabilities associated with this sample
  reliability: Record<string, Reliability>
}

export type EnvironmentalValuePoint = {
  // the geometry representing the point in GeoJSON.
  geom: {
    type: "Point"
    coordinates: number[]
  }
  dataset: string
  metadata: {
    objectid?: number
    schedule?: string
    map_no?: string
    name?: string
  }
}

export type EnvironmentalValueGeometry = {
  geom: {
    type: String
    // The following type instances handle line-strings, polygons/multi-line-strings and multipolygons respectively
    // (Should be  Array<Array<number>> | Array<Array<Array<number>>> | Array<Array<Array<Array<number>>>>).
    coordinates: any
  }
  dataset: string
  metadata: {
    objectid?: number
    schedule?: string
    map_no?: string
    name?: string
  }
}

/**
 *
 * @param sampledData
 * @param constraints
 * @returns
 */
export const sampledDataToDataCollections = (
  sampledData: SampledData,
  constraints: Map<string, Constraint>,
): E.Either<Error, Map<string, DataCollection>> => {
  const { samples } = sampledData

  const rawsByConstraint: Record<string, string[]> = {}
  const reliabilitiesByConstraint: Record<string, Reliability[]> = {}

  samples.forEach((sample) => {
    Object.entries(sample.values).forEach(([key, value]) => {
      if (value) {
        const raws = rawsByConstraint[key] ?? []
        rawsByConstraint[key] = [...raws, value]
      }
    })
    Object.entries(sample.reliability).forEach(([key, value]) => {
      if (value) {
        const rels = reliabilitiesByConstraint[key] ?? []
        reliabilitiesByConstraint[key] = [...rels, value]
      }
    })
  })

  const errorsAndResult = pipe(
    rawsByConstraint,
    R.mapWithIndex((key, raw) => {
      const constraint = constraints.get(key)
      if (constraint === undefined) return E.left(new Error("No project constraint " + key))
      const reliabilities = reliabilitiesByConstraint[key]

      const dataCollectionOrError = mkDataCollection(raw, reliabilities ?? [], constraint)
      if (E.isLeft(dataCollectionOrError)) return dataCollectionOrError
      // error case
      else return dataCollectionOrError
    }),
    R.separate,
  )

  const errors: Error[] = pipe(errorsAndResult.left, Object.values)
  const results = pipe(errorsAndResult.right, R.toArray)

  if (errors.length > 0) {
    const concatErrors = errors.map((e) => e.message).join(" ")
    return E.left(new Error(concatErrors))
  }

  return E.right(new Map(results))
}
