import { Tooltip, Button } from "@mui/material"
import { useCallback, useEffect } from "react"
import useAttributes from "../../../hooks/queries/useAttributes"
import useEnviroValuesGeom from "../../../hooks/queries/useEnviroValues"
import { useStoreActions } from "../../../state/hooks"

const TOOLTIP_MESSAGE = ""

const AutofillButton = () => {
  const { isFetching, refetch, error, isSuccess } = useAttributes()
  const { refetch: refetchEv } = useEnviroValuesGeom()

  const addAlert = useStoreActions((actions) => actions.alert.addAlert)
  const clearAlertsBySection = useStoreActions((actions) => actions.alert.clearAlertsBySection)

  const clearAlerts = useCallback(() => {
    clearAlertsBySection("autofill")
  }, [clearAlertsBySection])

  useEffect(() => {
    clearAlerts()
    refetch()
    refetchEv()
  }, [clearAlerts, refetch, refetchEv])

  useEffect(() => {
    if (error) {
      clearAlerts()
      addAlert({ message: (error as Error).message, removeable: false, severity: "error", section: "autofill" })
    }
  }, [clearAlerts, addAlert, error])

  const handleClick = async () => {
    clearAlerts()
    await refetch()
    if (isSuccess) {
      addAlert({ message: "Successfully autofilled!", removeable: true, severity: "success", section: "autofill" })
    }
  }

  return (
    <Tooltip title={TOOLTIP_MESSAGE}>
      <span>
        <Button onClick={handleClick} disabled={isFetching} color="primary">
          Autofill
        </Button>
      </span>
    </Tooltip>
  )
}

export default AutofillButton
