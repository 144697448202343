import { action, Action, ThunkOn, thunkOn } from "easy-peasy"
import { pipe } from "fp-ts/lib/function"
import * as A from "fp-ts/lib/Array"
import * as S from "fp-ts/lib/Set"
import { Eq, Ord } from "fp-ts/string"
import { StoreModel } from ".."
import { DataCollection } from "../../../types/DataCollection"
import { ConstraintValue } from "../../../types/ConstraintEntry"
import { SampledData } from "../../../types/SampledData"

export type ConstraintsModel = {
  // State

  /**
   * A dictionary of entries keyed by id.
   * Keep track of the value and the number of rules that reference this constraint.
   */
  entries: Map<string, number>

  userData: Map<string, ConstraintValue>
  serverData: SampledData | null
  serverDataByConstraint: Map<string, DataCollection>

  // Actions

  /**
   * Adds a constraint value entry if does not yet exist.
   * Otherwise, increments the reference count and sets the value.
   */
  add: Action<ConstraintsModel, { id: string }>

  /**
   * Decrements the reference count of this constraint value entry by one.
   * If the reference count is zero, the constraint value is removed.
   */
  remove: Action<ConstraintsModel, { id: string }>

  /**
   * Updates the value of the constraint value entry.
   * Does nothing if the constraint value does not yet exist (has a refCount of zero).
   */
  updateUser: Action<ConstraintsModel, { id: string; value: ConstraintValue }>

  /**
   * Updates the value of the constraint value entry.
   * Does nothing if the constraint value does not yet exist (has a refCount of zero).
   */
  updateServer: Action<ConstraintsModel, SampledData>
  updateServerDataByConstraint: Action<ConstraintsModel, Map<string, DataCollection>>

  /**
   * Clears the value of all constraints
   */
  clear: Action<ConstraintsModel>

  // Listeners
  onAddSelectedRule: ThunkOn<ConstraintsModel, {}, StoreModel>
  onRemoveSelectedRule: ThunkOn<ConstraintsModel, {}, StoreModel>
  onSetFramework: ThunkOn<ConstraintsModel, {}, StoreModel>
  onRemoveFramework: ThunkOn<ConstraintsModel, {}, StoreModel>

  onAddConstraint: ThunkOn<ConstraintsModel, {}, StoreModel>
}

const constraintsModel: ConstraintsModel = {
  entries: new Map(),
  userData: new Map(),
  serverData: null,
  serverDataByConstraint: new Map(),
  add: action((store, payload) => {
    const refCount = store.entries.get(payload.id)
    if (refCount !== undefined) {
      // The entry already exists.
      // Increment the refCount by one
      store.entries.set(payload.id, refCount + 1)
    } else {
      // Create the entry
      store.entries.set(payload.id, 1)
    }
  }),
  remove: action((store, payload) => {
    const refCount = store.entries.get(payload.id)

    // Remove the entry ONLY if it is no longer referenced by a rule
    if (refCount !== undefined) {
      if (refCount >= 2) {
        // Decrement the refCount by one
        store.entries.set(payload.id, refCount - 1)
      } else {
        // Remove the entry
        store.entries.delete(payload.id)
      }
    }
  }),
  updateUser: action((store, payload) => {
    store.userData.set(payload.id, payload.value)
  }),
  updateServer: action((store, payload) => {
    store.serverData = payload
  }),
  updateServerDataByConstraint: action((store, payload) => {
    store.serverDataByConstraint = payload
  }),
  clear: action((store) => {
    const site = store.userData.get("site")
    store.userData = new Map()
    store.userData.set("site", site || "")
    store.serverData = null
    //store.entries.forEach((value, key) => store.entries.set(key, { ...value, data: {} }))
  }),
  onAddSelectedRule: thunkOn(
    (_, storeActions) => storeActions.session.rules.add,
    (actions, payload, { getStoreState }) => {
      const rule = getStoreState().project.rules.get(payload.payload.id)
      if (rule === undefined) return
      pipe(
        rule.definition,
        A.map(({ constraints }) => constraints),
        A.flatten,
        S.fromArray(Eq),
        S.toArray(Ord),
      ).forEach((constraint) => actions.add({ id: constraint }))
    },
  ),
  onRemoveSelectedRule: thunkOn(
    (_, storeActions) => storeActions.session.rules.remove,
    (actions, payload, { getStoreState }) => {
      const rule = getStoreState().project.rules.get(payload.payload.id)
      if (rule === undefined) return
      pipe(
        rule.definition,
        A.map(({ constraints }) => constraints),
        A.flatten,
        S.fromArray(Eq),
        S.toArray(Ord),
      ).forEach((constraint) => actions.remove({ id: constraint }))
    },
  ),
  onSetFramework: thunkOn(
    (_, storeActions) => storeActions.session.map.setFramework,
    (actions, payload) => {
      actions.updateUser({ id: "site", value: payload.payload })
    },
  ),
  onRemoveFramework: thunkOn(
    (_, storeActions) => storeActions.session.map.removeFramework,
    (actions, payload) => {
      actions.updateUser({ id: "site", value: "" })
    },
  ),
  onAddConstraint: thunkOn(
    (action) => action.add,
    (actions, payload, { getStoreState }) => {
      // Framework
      if (payload.payload.id === "site")
        actions.updateUser({
          id: "site",
          value: getStoreState().session.map.framework ?? "",
        })
    },
  ),
}

export default constraintsModel
