import React from "react"
import { styled } from "@mui/material/styles"
import { Button, Box, Popover, Typography, Container } from "@mui/material"
import { useStoreState } from "../../../state/hooks"

const PREFIX = "DefinitionPopUp"

const classes = {
  link: `${PREFIX}-link`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.link}`]: {
    padding: 0,
    minWidth: 0,
    align: "left",
    textTransform: "none",
  },
}))

type Props = {
  id: string // The constraint id
}

const DefinitionPopUp = ({ id }: Props) => {
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const constraint = useStoreState((state) => state.project.constraints.get(id))

  return (
    <StyledBox>
      <Button className={classes.link} component="button" onClick={handleClick}>
        <Typography color={"textSecondary"} align={"left"} variant={"caption"}>
          {constraint?.name}
        </Typography>
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        anchorReference={"anchorEl"}
        open={open}
        onClose={handleClose}
      >
        <Container sx={{ padding: 1, "&.MuiContainer-root": { maxWidth: "400px" } }}>
          <Typography>{constraint?.name}</Typography>
          <Typography variant={"body2"}>{constraint?.glossaryEntry?.shortDefinition}</Typography>
        </Container>
      </Popover>
    </StyledBox>
  )
}

export default DefinitionPopUp
