import { action, Action, computed, Computed, thunk, Thunk } from "easy-peasy"
import { freeze } from "immer"
import { getConstraints, getSites } from "../../data"
import { Constraint, Crop, Project, Rule } from "../../types/types"

export type ProjectModel = {
  // State

  name: string
  version: string

  /**
   * The canonical set of Rules by id
   */
  rules: Map<string, Rule>
  rulesAsList: Computed<ProjectModel, string[]>

  /**
   * The canonical set of Constraints by id.
   */
  constraints: Map<string, Constraint>
  constraintsAsList: Computed<ProjectModel, string[]>

  /**
   * The canonical set of Crops by crop code
   */
  crops: Map<string, Crop>
  cropsAsList: Computed<ProjectModel, Crop[]>

  /**
   * The canonical set of Frameworks
   */
  frameworks: Set<string>
  frameworksAsList: Computed<ProjectModel, string[]>

  // Actions
  setName: Action<ProjectModel, string>
  setVersion: Action<ProjectModel, string>
  setRules: Action<ProjectModel, Rule[]>
  setConstraints: Action<ProjectModel, Constraint[]>
  setCrops: Action<ProjectModel, Crop[]>
  setFrameworks: Action<ProjectModel, string[]>

  // Thunks
  fetchProject: Thunk<ProjectModel, Project>
}

const model: ProjectModel = {
  name: "Land Suitability Calculator",
  version: "dev",
  rules: new Map(),
  constraints: new Map(),
  crops: new Map(),
  rulesAsList: computed((state) => Array.from(state.rules.keys())),
  constraintsAsList: computed((state) => Array.from(state.constraints.keys())),
  cropsAsList: computed((state) => Array.from(state.crops.values())),
  frameworksAsList: computed((state) => Array.from(state.frameworks.values())),
  frameworks: new Set(),
  setName: action((state, payload) => {
    state.name = payload
  }),
  setVersion: action((state, payload) => {
    state.version = payload
  }),
  setRules: action((state, payload) => payload.forEach((rule) => state.rules.set(rule.id, rule))),
  setConstraints: action((state, payload) =>
    payload.forEach((constraint) => state.constraints.set(constraint.id, constraint)),
  ),
  setCrops: action((state, payload) => payload.forEach((crop) => state.crops.set(crop.id, crop))),
  setFrameworks: action((state, payload) => payload.forEach((framework) => state.frameworks.add(framework))),
  fetchProject: thunk(async (action, project) => {
    try {
      const frozenProject = freeze(project, true)
      action.setName(frozenProject.name)
      action.setVersion(frozenProject.version)
      action.setCrops(frozenProject.crops)
      action.setRules(frozenProject.rules)
      action.setConstraints(getConstraints(frozenProject.constraints))
      action.setFrameworks(getSites(frozenProject.constraints)?.categories ?? [])
    } catch (e) {
      alert(e)
      console.log(e)
    }
  }),
}

export default model
