import * as A from "fp-ts/lib/Array"

import { action, thunk, Action, Thunk, computed, Computed } from "easy-peasy"
import { pipe } from "fp-ts/lib/function"

import { StoreModel } from ".."
import { Constraint, Crop, Rule, RuleScores } from "../../../types/types"
import { ConstraintReport, CropReport, mkConstraintReport, mkRuleReports, RuleReport } from "../../../types/Report"
import { SampledData } from "../../../types/SampledData"
import { DataCollection } from "../../../types/DataCollection"
import { Remote } from "comlink"
import { Calculator } from "../../../workers/calculator.worker"
import { mkCellsWithFacts } from "../../../calculate"
import { sampleToVectorSource } from "../../../components/sections/region/GBRMap/utils"
import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import { Geometry } from "ol/geom"
import { Score } from "../../../types/Score"
import { FactStore } from "@bitproxima/decision-tree/dist/types"

type CropResultLayer = {
  crop: string
  layer: VectorLayer<VectorSource<Geometry>>
}

type CellData = {
  score?: Score
  factStore: FactStore
  ruleScores: RuleScores
  coordinate: number[]
}

type Result = {
  rules: RuleReport[]
  constraints: ConstraintReport[]
  results: CropReport[]
}

export type ReportModel = {
  // State
  results: {
    combined: Result | null
    canonical: Result | null
  }
  isCalculating: boolean

  selection?: {
    image?: string // An image of the selection as a Base64 PNG
    coordinates?: [number, number] // Centroid lat long
  }
  evSelection?: {
    image?: string // An image of the selection as a Base64 PNG
    coordinates?: [number, number] // Centroid lat long
  }
  cropResultLayers: CropResultLayer[]
  activeCropLayerIdx: number | null

  selectedCellData: CellData // this lives in the store so that the results table can grab data from the ol map cell

  activeCropLayer: Computed<ReportModel, CropResultLayer | null>

  setSelectionImage: Action<ReportModel, string>
  setSelectionCoordinates: Action<ReportModel, [number, number]>
  setEvImage: Action<ReportModel, string>
  setEvCoordinates: Action<ReportModel, [number, number]>

  setIsCalculating: Action<ReportModel, boolean>
  setResults: Action<ReportModel, { combined: Result; canonical: Result }>
  clearResults: Action<ReportModel>

  clear: Action<ReportModel>

  setCropResultLayers: Action<ReportModel, CropResultLayer[]>
  clearCropResultLayers: Action<ReportModel>

  setActiveCropLayer: Action<ReportModel, string>
  setActiveCropLayerIdx: Action<ReportModel, number | null>

  setSelectedCellData: Action<ReportModel, CellData>

  calculateResult: Thunk<ReportModel, void, {}, StoreModel>
}

const reportModel: ReportModel = {
  results: { combined: null, canonical: null },
  selection: undefined,
  evSelection: undefined,
  isCalculating: false,
  cropResultLayers: [],
  activeCropLayerIdx: null,
  selectedCellData: { score: undefined, factStore: new Map(), ruleScores: [], coordinate: [] },
  activeCropLayer: computed((state) => {
    if (
      !state.cropResultLayers ||
      state.activeCropLayerIdx === null ||
      state.cropResultLayers.length <= state.activeCropLayerIdx
    )
      return null
    return state.cropResultLayers[state.activeCropLayerIdx]
  }),
  setActiveCropLayer: action((state, cropName) => {
    const index = state.cropResultLayers.findIndex(({ crop }) => crop === cropName)

    if (index === -1) state.activeCropLayerIdx = null
    state.activeCropLayerIdx = index
  }),
  setActiveCropLayerIdx: action((state, payload) => {
    state.activeCropLayerIdx = payload
  }),
  setSelectedCellData: action((state, payload) => {
    state.selectedCellData = payload
  }),
  setCropResultLayers: action((state, payload) => {
    state.cropResultLayers = payload
  }),
  clearCropResultLayers: action((state) => {
    state.cropResultLayers = []
  }),
  setResults: action((state, payload) => {
    state.results = payload
  }),
  clear: action((state) => {
    state.results = { combined: null, canonical: null }
    state.selection = undefined
    state.activeCropLayerIdx = null
    state.cropResultLayers = []
  }),
  clearResults: action((state) => {
    state.results = { combined: null, canonical: null }
  }),
  setIsCalculating: action((state, payload) => {
    state.isCalculating = payload
  }),
  setSelectionCoordinates: action((state, payload) => {
    state.selection = state.selection ?? {}
    state.selection.coordinates = payload
  }),
  setSelectionImage: action((state, payload) => {
    state.selection = state.selection ?? {}
    state.selection.image = payload
  }),
  setEvCoordinates: action((state, payload) => {
    state.evSelection = state.evSelection ?? {}
    state.evSelection.coordinates = payload
  }),
  setEvImage: action((state, payload) => {
    state.evSelection = state.evSelection ?? {}
    state.evSelection.image = payload
  }),
  calculateResult: thunk(async (actions, _, { getStoreState, getStoreActions }) => {
    const constraints = getStoreState().session.selectedConstraints
    const userData = getStoreState().session.constraints.userData
    const serverDataByConstraint = getStoreState().session.constraints.serverDataByConstraint

    const samples = getStoreState().session.constraints.serverData
    const rules = getStoreState().session.selectedRules
    const crops = getStoreState().session.selectedCrops
    const worker = getStoreState().workers.calculateWorker

    if (samples === null) return

    actions.setIsCalculating(true)

    // calculate results
    actions.clearResults()
    const results = await calculateUserServerResults(
      rules,
      constraints,
      crops,
      userData,
      serverDataByConstraint,
      samples,
      worker,
    )

    // When calculating results with server only data, userData is not required.
    const serverResults = await calculateServerResults(
      rules,
      constraints,
      crops,
      userData.get("site") || "",
      serverDataByConstraint,
      samples,
      worker,
    )

    // create crop result layers
    actions.setResults({ combined: results, canonical: serverResults })

    getStoreActions().session.report.clearCropResultLayers()
    const cropResultLayers = results.results.map((result) => ({
      crop: result.name,
      layer: new VectorLayer({
        source: sampleToVectorSource(result.sampleReport),
      }),
    }))
    getStoreActions().session.report.setCropResultLayers(cropResultLayers)

    actions.setIsCalculating(false)
  }),
}

const calculateUserServerResults = async (
  rules: Rule[],
  constraints: Constraint[],
  crops: Crop[],
  userData: Map<string, string>,
  serverDataByConstraint: Map<string, DataCollection>,
  samples: SampledData,
  worker: Remote<Calculator>,
): Promise<Result> => {
  const constraintsMap = new Map(constraints.map((c) => [c.id, c]))
  const ruleReports = mkRuleReports(rules, constraintsMap)
  const constraintReports = pipe(
    constraints,
    A.map((constraint) => {
      const userValue = userData.get(constraint.id)
      const serverValue = serverDataByConstraint.get(constraint.id)?.value

      const serverValueAsString = typeof serverValue === "number" ? serverValue.toFixed(2) : serverValue

      const value = userValue ?? serverValueAsString ?? ""

      let source = ""
      if (userValue !== undefined) source = "User"
      else if (serverValue !== undefined) source = "Tool"

      let metadata: Record<string, string> = {}
      if (serverValue !== undefined) {
        const stats = serverDataByConstraint.get(constraint.id)?.stats ?? {}
        Object.entries(stats).forEach(([key, value]) => {
          if (typeof value === "number") metadata[key] = value.toFixed(2)
          else metadata[key] = (value as Object).toString()
        })
      }
      const reliability = serverDataByConstraint.get(constraint.id)?.reliability

      return mkConstraintReport(constraint, value, source, metadata, reliability)
    }),
  )

  const userDataAsRecord = Object.fromEntries(userData.entries())
  const cellDatas = mkCellsWithFacts(constraints, userDataAsRecord, samples)

  const ruleIds = rules.map((rule) => rule.id)
  const cropReports = await worker.calculate(ruleIds, cellDatas, crops)

  return { rules: ruleReports, constraints: constraintReports, results: cropReports }
}

const calculateServerResults = async (
  rules: Rule[],
  constraints: Constraint[],
  crops: Crop[],
  site: string,
  serverDataByConstraint: Map<string, DataCollection>,
  samples: SampledData,
  worker: Remote<Calculator>,
): Promise<Result> => {
  const constraintsMap = new Map(constraints.map((c) => [c.id, c]))
  const ruleReports = mkRuleReports(rules, constraintsMap)
  const constraintReports = pipe(
    constraints,
    A.map((constraint) => {
      const serverValue = serverDataByConstraint.get(constraint.id)?.value

      const serverValueAsString = typeof serverValue === "number" ? serverValue.toFixed(2) : serverValue

      const value = serverValueAsString ?? (constraint.id === "site" ? site : null) ?? ""

      let source = ""
      if (constraint.id === "site") source = "User"
      else if (serverValue !== undefined) source = "Tool"

      let metadata: Record<string, string> = {}
      if (serverValue !== undefined) {
        const stats = serverDataByConstraint.get(constraint.id)?.stats ?? {}
        Object.entries(stats).forEach(([key, value]) => {
          if (typeof value === "number") metadata[key] = value.toFixed(2)
          else metadata[key] = (value as Object).toString()
        })
      }
      const reliability = serverDataByConstraint.get(constraint.id)?.reliability

      return mkConstraintReport(constraint, value, source, metadata, reliability)
    }),
  )

  // An empty object is passed in the following function in place of userData.
  const cellDatas = mkCellsWithFacts(constraints, { site: site }, samples)

  const ruleIds = rules.map((rule) => rule.id)
  const cropReports = await worker.calculate(ruleIds, cellDatas, crops)

  return { rules: ruleReports, constraints: constraintReports, results: cropReports }
}

export default reportModel
