import { Paper, InputBase, IconButton } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Search } from "@mui/icons-material"
import { ChangeEvent } from "react"

const PREFIX = "SearchInput"

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  icon: `${PREFIX}-icon`,
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.input}`]: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  [`& .${classes.icon}`]: {
    padding: 4,
  },
}))

type Props = {
  value?: string
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

const SearchInput = (props: Props) => {
  return (
    <StyledPaper className={classes.root} variant="outlined">
      <InputBase
        className={classes.input}
        onChange={props.onChange}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        value={props.value}
      />
      <IconButton className={classes.icon} size="large">
        <Search />
      </IconButton>
    </StyledPaper>
  )
}

export default SearchInput
