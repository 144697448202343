import TitleBar from "./sections/TitleBar"
import Main from "./sections/Main"
import Footer from "./sections/Footer"

const CalculatePage = () => {
  return (
    <>
      <TitleBar />
      <Main />
      <Footer />
    </>
  )
}
export default CalculatePage
