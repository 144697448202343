import { action, Action} from "easy-peasy"
import { Polygon } from "ol/geom"

type SelectionState = "not started" | "in progress" | "completed"

export type MapModel = {
  framework: string | null
  polygon: Polygon | null

  selectionState: SelectionState

  // Actions
  setFramework: Action<MapModel, string>
  removeFramework: Action<MapModel>
  setPolygon: Action<MapModel, Polygon>
  removePolygon: Action<MapModel>

  setSelectionState: Action<MapModel, SelectionState>
}

const mapModel: MapModel = {
  framework: null,
  polygon: null,
  selectionState: "not started",
  setFramework: action((state, payload) => {
    state.framework = payload
  }),
  removeFramework: action((state) => {
    state.framework = null
  }),
  setPolygon: action((state, payload) => {
    state.polygon = payload
  }),
  removePolygon: action((state) => {
    state.polygon = null
  }),
  setSelectionState: action((state, payload) => {
    state.selectionState = payload
  }),
}

export default mapModel
