import { useState } from "react"
import { styled } from "@mui/material/styles"

import { CategoricalConstraint } from "../../../../types/types"
import { Select, MenuItem, FormControl, SelectChangeEvent } from "@mui/material"
import { useStoreActions, useStoreState } from "../../../../state/hooks"
import { Metadata } from "./Metadata"
import { getUserServerValue } from "../../../../types/ConstraintEntry"
import DefinitionPopUp from "../DefinitionPopUp"

const PREFIX = "AttributeCategorical"

const classes = {
  formControl: `${PREFIX}-formControl`,
  paper: `${PREFIX}-paper`,
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`&.${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 250,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
  },
}))

type Props = {
  constraint: CategoricalConstraint
  disabled: boolean
}

const AttributeCategorical = ({ constraint, disabled }: Props) => {
  const update = useStoreActions((action) => action.session.constraints.updateUser)

  const userData = useStoreState((state) => state.session.constraints.userData).get(constraint.id)
  const serverData = useStoreState((state) => state.session.constraints.serverDataByConstraint).get(constraint.id)
  const entry = { user: userData, server: serverData }
  const [edited, setEdited] = useState(false)

  const value = getUserServerValue(entry)

  const onChange = (event: SelectChangeEvent<string>) => {
    update({ id: constraint.id, value: event.target.value as string })
    setEdited(true)
  }

  return (
    <StyledFormControl className={classes.formControl}>
      <DefinitionPopUp id={constraint.id} />
      <Select
        labelId="attribute-input-label"
        id="attribute-label"
        value={value}
        onChange={onChange}
        label={constraint.name}
        fullWidth
        disabled={disabled}
        variant="standard"
        //renderValue={renderValue}
      >
        {constraint.categories.map((item: string, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <Metadata edited={edited} entry={entry} />
    </StyledFormControl>
  )
}

export default AttributeCategorical
