import React, { useState, useEffect, useMemo } from "react"
import { styled } from "@mui/material/styles"

import { ContinuousConstraint, inRange, Range } from "../../../../types/types"
import { FormControl, FormHelperText, Input } from "@mui/material"
import { useStoreActions, useStoreState } from "../../../../state/hooks"
import { Metadata } from "./Metadata"
import { getUserServerValue } from "../../../../types/ConstraintEntry"
import DefinitionPopUp from "../DefinitionPopUp"

const PREFIX = "AttributeNumerical"

const classes = {
  formControl: `${PREFIX}-formControl`,
  paper: `${PREFIX}-paper`,
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`&.${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
}))

type Props = {
  constraint: ContinuousConstraint
  disabled: boolean
}

const AttributeNumerical = ({ constraint, disabled }: Props) => {
  const update = useStoreActions((action) => action.session.constraints.updateUser)
  const userData = useStoreState((state) => state.session.constraints.userData).get(constraint.id)
  const serverData = useStoreState((state) => state.session.constraints.serverDataByConstraint).get(constraint.id)
  const entry = useMemo(() => ({ user: userData, server: serverData }), [serverData, userData])

  const [value, setValue] = useState<string>("")
  const [error, setError] = useState<string | null>(null)
  const [domain, setDomain] = useState<Range | null>(null)

  // Set value upon constraint.domain or entry update.
  useEffect(() => {
    setValue(getUserServerValue(entry))
    setError(null)

    if (constraint.domain !== undefined) {
      setDomain(constraint.domain)
    }
  }, [constraint.domain, entry])

  /*
  // When the parent component's autofilled changes, empty the field
  useEffect(() => {
    if (serverData?.value === null) {
      setValue("")
    }
    setError(null)
  }, [autofilled, serverData])
  */

  const handleUpdate = (input: string) => {
    const inputAsFloat = parseFloat(input)

    if (isNaN(inputAsFloat)) {
      if (input.length === 0) setError(null)
      else if (input.length === 1 && input === "-") setError(null)
      else setError("Must be numeric value")
    } else {
      if (domain && !inRange(inputAsFloat, domain)) {
        setError("Enter a number between " + domain.lower + " and " + domain.upper)
      } else {
        update({ id: constraint.id, value: input })
        setError(null)
      }
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value
    setValue(input)
    setError(null)
    handleUpdate(input)
  }

  return (
    <StyledFormControl className={classes.formControl}>
      <DefinitionPopUp id={constraint.id} />
      <Input
        type="string"
        inputProps={{ min: domain?.lower, max: domain?.upper }}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
      <FormHelperText error={error !== null}>{error}</FormHelperText>
      <Metadata edited={false} entry={entry} /> {/*The edited={false} is a dummy value*/}
    </StyledFormControl>
  )
}

export default AttributeNumerical
