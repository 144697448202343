import { Geometry } from "ol/geom"
import GeometryType from "ol/geom/GeometryType"
import { Draw } from "ol/interaction"
import TileLayer from "ol/layer/Tile"
import VectorLayer from "ol/layer/Vector"
import { TileArcGISRest, OSM, Vector } from "ol/source"
import VectorSource from "ol/source/Vector"
import Geocoder from "ol-geocoder"

const QLD_PARCEL_URL =
  "https://spatial-gis.information.qld.gov.au/arcgis/rest/services/PlanningCadastre/LandParcelPropertyFramework/MapServer"

const QLD_SATELLITE_URL =
  "https://spatial-img.information.qld.gov.au/arcgis/rest/services/Basemaps/LatestStateProgram_AllUsers/ImageServer"

export const mkCadastralLayer = () =>
  new TileLayer({
    minZoom: 13,
    source: new TileArcGISRest({
      url: QLD_PARCEL_URL,
      params: {
        LAYERS: "show:0,4,7",
      },
    }),
  })

export const mkSatelliteLayer = () =>
  new TileLayer({
    opacity: 1,
    source: new TileArcGISRest({
      attributions: [
        "Includes material © State of Queensland (Department of Resources)",
        "© Planet Labs Netherlands B.V. reproduced under licence from Planet and Geoplex, All Rights Reserved, 2021.",
      ],
      url: QLD_SATELLITE_URL,
      crossOrigin: "Anonymous",
    }),
  })

export const mkOSMLayer = () => new TileLayer({ source: new OSM() })

export const mkGBRLayer = (vector: Vector<Geometry>) => new VectorLayer({ opacity: 0.7, source: vector })

export const mkDraw = (): [Draw, VectorLayer<VectorSource<Geometry>>, VectorSource<Geometry>] => {
  const drawSource = new VectorSource()
  const drawLayer = new VectorLayer({ source: drawSource })
  const draw = new Draw({ source: drawSource, type: GeometryType["POLYGON"] })

  return [draw, drawLayer, drawSource]
}

export const mkGeocoder = () =>
  new Geocoder("nominatim", {
    provider: "osm",
    lang: "en-AU",
    placeholder: "Search for ...",
    targetType: "glass-button",
    countrycodes: "au",
    limit: 5,
    keepOpen: true,
  })
