import React from "react"
import ReactDOM from "react-dom"
import { enableMapSet } from "immer"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { StoreProvider } from "easy-peasy"
import store from "./state/store"
import "@fontsource/roboto"
import "@fontsource/roboto/500.css"
import { CssBaseline } from "@mui/material"
import { QueryClient, QueryClientProvider } from "react-query"

enableMapSet()

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StoreProvider store={store}>
        <CssBaseline>
          <App />
        </CssBaseline>
      </StoreProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
