// hooks.js
import { createTypedHooks } from "easy-peasy"
import { StoreModel } from "./model"

const typedActions = createTypedHooks<StoreModel>()

export const useStoreActions = typedActions.useStoreActions
export const useStoreDispatch = typedActions.useStoreDispatch
export const useStoreState = typedActions.useStoreState
export const useStore = typedActions.useStore
