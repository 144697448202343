import React from "react"
import { styled } from "@mui/material/styles"
import { Box, LinearProgress, Typography } from "@mui/material"

import AttributeInput from "./AttributeInput/AttributeInput"
import { useStoreState } from "../../../state/hooks"
import { isSiteConstraint } from "../../../types/types"
import { Alert } from "@mui/material"
import AutofillButton from "./AutofillButton"
import AlertManager from "../../common/AlertManager"
import useAttributes from "../../../hooks/queries/useAttributes"
import strings from "../../../strings"

const PREFIX = "AttributeSection"

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled("div")(() => ({
  [`& .${classes.root}`]: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
  },
}))

const Info = () => {
  return <Alert severity="info">Complete Step 2.</Alert>
}

const SoilAttributes = () => {
  const selectedConstraints = useStoreState((state) => state.session.selectedConstraints)
    .filter((constraint) => !isSiteConstraint(constraint))
    .sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0))
  const { isFetching } = useAttributes()
  const alerts = useStoreState((state) => state.alert.autofillAlerts)

  return (
    <Root>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography component="h6" variant="h6">
            {strings.ATTRIBUTES_STEP.ATTRIBUTES_TITLE}
          </Typography>
          <Typography variant="body2">
            {strings.ATTRIBUTES_STEP.ATTRIBUTES_SUBTITLE}
          </Typography>
        </Box>

        <Box>
          <AutofillButton />
        </Box>
      </Box>
      <AlertManager alerts={alerts} />
      <Box>
        {selectedConstraints.length === 0 ? (
          <Info />
        ) : (
          <Box className={classes.root}>
            {selectedConstraints.map((constraint) => (
              <AttributeInput key={constraint.id} constraint={constraint} disabled={isFetching} />
            ))}
          </Box>
        )}
      </Box>
      {isFetching ? <LinearProgress /> : null}
    </Root>
  )
}

export default React.memo(SoilAttributes)
