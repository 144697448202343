import { yellow, green, lightGreen, lime, orange, red, grey, blue } from "@mui/material/colors"

const colors = {
  green: green[300],
  lightGreen: lightGreen[300],
  lime: lime[300],
  orange: orange[300],
  red: red[300],
  grey: grey[200],
  yellow: yellow[300],
}

export const resultColor1 = colors.green
export const resultColor2 = colors.lightGreen
export const resultColor3 = colors.lime
export const resultColor4 = colors.orange
export const resultColor5 = colors.red

export const variabilityColors = { low: colors.green, medium: colors.yellow, high: colors.red }
export const tableColorR1 = colors.grey

export const backgroundColor = colors.grey

export const evColors = {
  // The string addition is to adjust the alpha value (transparency) of the colors.
  evColor1: "#eeff4188",
  evColor2: yellow[900] + "88",
  evColor3: green[200] + "7a",
  evColor4: blue[600] + "66",
  evColor5: red[500] + "aa",
  evColor6: lightGreen[700] + "88",
}
