import Alert from "@mui/material/Alert"
import { useStoreActions } from "../../state/hooks"
import { Alert as AlertModel } from "../../state/model/alert"

type Props = {
  alerts: AlertModel[]
}

type CustomAlertProps = {
  alert: AlertModel
  onRemove: (id: number) => void
}

const CustomAlert = (props: CustomAlertProps) => {
  const { onRemove, alert } = props

  const add = useStoreActions((state) => state.alert.addAlert)

  const onClose = alert.removeable
    ? () => {
        onRemove(alert.id)
        if (alert?.description === "high variability") {
          // if the alert is due to a high variability:
          add({ ...alert, severity: "warning", removeable: false }) // re-add the alert with downgraded severity
        }
      }
    : undefined

  const severity = alert.severity

  return (
    <Alert severity={severity} onClose={onClose}>
      {alert.message}
    </Alert>
  )
}

const AlertManager = (props: Props) => {
  const removeAlert = useStoreActions((actions) => actions.alert.removeAlert)

  return (
    <>
      {props.alerts.map((alert, idx) => (
        <CustomAlert key={idx} alert={alert} onRemove={removeAlert} />
      ))}
    </>
  )
}

export default AlertManager
