import { action, Action } from "easy-peasy"

export type CropsModel = {
  // State

  /** A set of selected crops */
  crops: Set<string>

  // Actions

  /** Adds a crop to the selected set */
  add: Action<CropsModel, string>

  /** Removes a crop from the selected set */
  remove: Action<CropsModel, string>

  /** Adds a list of crops to the selected set */
  addList: Action<CropsModel, string[]>

  /** Removes a list of crops from the selected set */
  removeList: Action<CropsModel, string[]>
}

const cropsModel: CropsModel = {
  crops: new Set(),
  add: action((state, payload) => {
    state.crops.add(payload)
  }),
  remove: action((state, payload) => {
    state.crops.delete(payload)
  }),
  addList: action((state, payload) => {
    payload.map((crop) => state.crops.add(crop))
  }),
  removeList: action((state, payload) => {
    payload.map((crop) => state.crops.delete(crop))
  }),
}

export default cropsModel
