import { isLeft } from "fp-ts/lib/Either"
import GeoJSON from "ol/format/GeoJSON"
import { useQuery } from "react-query"

import { fetchSampledPolygon, GeometryIn } from "../../data"
import { useStoreActions, useStoreState } from "../../state/hooks"
import { sampledDataToDataCollections } from "../../types/SampledData"
import useProject from "./useProject"

/**
 * Hook that wraps a fetchSampledPolygon query
 * and uses the polygon in the store.
 * @returns
 */
const useAttributes = () => {
  const polygon = useStoreState((state) => state.session.map.polygon)

  if (!polygon) throw new Error("useAttributes hook can only be used when a feature has been selected.")

  const featureGeoJson = new GeoJSON().writeGeometryObject(polygon) as GeometryIn
  const srid = 4326

  return _useAttributes(featureGeoJson, srid)
}

/**
 * Hook that wraps a fetchSampledPolygon query
 * @param feature feature in GeoJSON
 * @param srid spatial reference id
 * @returns
 */
const _useAttributes = (feature: GeometryIn, srid: number) => {
  const { data: project } = useProject()
  const clear = useStoreActions((actions) => actions.session.constraints.clear)
  const updateServer = useStoreActions((actions) => actions.session.constraints.updateServer)
  const updateServerDataByConstraint = useStoreActions(
    (actions) => actions.session.constraints.updateServerDataByConstraint,
  )

  const query = useQuery(
    ["attributes", "sampled_polygon", feature, srid],
    async () => {
      const data = await fetchSampledPolygon(feature, srid)

      if (!project) throw new Error("Undefined project.")
      const constraintsMap = new Map(project.constraints.map((constraint) => [constraint.id, constraint]))

      // Attempt to convert sample data into DataCollections
      const dataCollectionsOrError = sampledDataToDataCollections(data, constraintsMap)

      if (isLeft(dataCollectionsOrError)) {
        // Error converting data to DataCollections
        const error = dataCollectionsOrError.left
        throw error
      } else {
        // Success case
        const dataCollections = dataCollectionsOrError.right
        clear()
        updateServer(data)
        updateServerDataByConstraint(dataCollections)
      }

      return data
    },
    {
      // The query will not execute until there is a project
      enabled: !!project,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  return query
}

export default useAttributes
