import { Suspense, lazy } from "react"
import { styled } from "@mui/material/styles"
import { Button, Container, LinearProgress, Paper, Step, StepLabel, Stepper } from "@mui/material"

import RegionSection from "./region/RegionSection"
import AttributeSection from "./attributes/AttributeSection"
import LimitationSection from "./limitations/LimitationsSection"
import CropSection from "./crop/CropSection"
import useStep from "../../hooks/useStep"
import { useStoreState } from "../../state/hooks"
import strings from "../../strings"

const PREFIX = "Main"

const classes = {
  main: `${PREFIX}-main`,
  paper: `${PREFIX}-paper`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  stepperPaper: `${PREFIX}-stepperPaper`,
  prevStep: `${PREFIX}-prevStep`,
}

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&` /*`.${classes.main}`*/]: {
    // TODO why is this large, not medium?
    // backgroundColor: red[200],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    flexGrow: 1,
    maxWidth: 960,
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },

  [`& .${classes.buttonContainer}`]: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
  },

  [`& .${classes.stepperPaper}`]: {
    margin: theme.spacing(1),
    padding: theme.spacing(3),
  },

  [`& .${classes.prevStep}`]: {
    marginRight: theme.spacing(2),
  },
}))

const ResultSection = lazy(() => import("./result/ResultSection"))

const steps = [
  {
    id: 0,
    label: strings.REGION_STEP.STEP_TITLE,
    elem: () => <RegionSection />,
  },
  {
    id: 1,
    label: strings.ATTRIBUTES_STEP.STEP_TITLE,
    elem: () => (
      <>
        <LimitationSection />
        <AttributeSection />
      </>
    ),
  },
  {
    id: 2,
    label: strings.CROPS_STEP.STEP_TITLE,
    elem: () => <CropSection />,
  },
  {
    id: 3,
    label: strings.RESULTS_STEP.STEP_TITLE,
    elem: () => <Suspense fallback={<LinearProgress />}>{<ResultSection />}</Suspense>,
  },
]

const Main = () => {
  const { activeStepIdx, activeStep, prevStep, nextStep, canPrev, canNext, isCompleted } = useStep(steps)

  // Step 1
  const polygonExists = useStoreState((state) => state.session.map.polygon !== null)
  const frameworkExists = useStoreState((state) => state.session.map.framework !== null)

  // Step 2
  const hasAutofillErrors = useStoreState(
    (state) => state.alert.autofillAlerts.filter((alert) => alert.severity === "error").length > 0,
  )

  // Step 3
  const cropsSelected = useStoreState((state) => state.session.selectedCrops.length > 0)

  const isNextDisabled =
    !canNext ||
    (() => {
      switch (activeStepIdx) {
        case 0:
          return !polygonExists || !frameworkExists
        case 1:
          return hasAutofillErrors
        case 2:
          return !cropsSelected
        default:
          return false
      }
    })()

  return (
    <StyledContainer className={classes.main}>
      <Paper className={classes.stepperPaper}>
        <Stepper activeStep={activeStepIdx}>
          {steps.map(({ id, label }) => {
            return (
              <Step completed={isCompleted(id)} key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </Paper>
      <Paper className={classes.paper}>{activeStep !== undefined ? activeStep.elem() : null}</Paper>
      <Container className={classes.buttonContainer}>
        <Button className={classes.prevStep} onClick={prevStep} variant="contained" disabled={!canPrev}>
          {strings.GENERAL_ELEMENTS.PREV_STEP_BUTTON}
        </Button>
        <Button onClick={nextStep} variant="contained" color="primary" disabled={isNextDisabled}>
          {strings.GENERAL_ELEMENTS.NEXT_STEP_BUTTON}
        </Button>
      </Container>
    </StyledContainer>
  )
}

export default Main
