import * as O from "fp-ts/lib/Option"
import { resultColor1, resultColor2, resultColor3, resultColor4, resultColor5 } from "../colors"

export enum Score {
  One = 1,
  Two,
  Three,
  Four,
  Five,
}

export const parse = (input: string | number): O.Option<Score> => {
  const inputString = input.toString()

  switch (inputString) {
    case "1":
      return O.some(Score.One)
    case "2":
      return O.some(Score.Two)
    case "3":
      return O.some(Score.Three)
    case "4":
      return O.some(Score.Four)
    case "5":
      return O.some(Score.Five)
  }
  return O.none
}

export const getColor = (score: Score): string => {
  switch (score) {
    case Score.One:
      return resultColor1
    case Score.Two:
      return resultColor2
    case Score.Three:
      return resultColor3
    case Score.Four:
      return resultColor4
    case Score.Five:
      return resultColor5
  }
}
