import { Box, CircularProgress, GlobalStyles, Typography } from "@mui/material"

import CalculatePage from "./components/CalculatePage"
import DisclaimerDialog from "./components/DisclaimerDialog"
import useProject from "./hooks/queries/useProject"
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles"
import { backgroundColor } from "./colors"

/*
// Uncomment this block and import Theme if you import anything from @mui/styles
// Find more info at the top of the mui v5 migration guide: https://mui.com/guides/migration-v4/
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
*/

let theme = createTheme({ palette: { primary: { main: "#3f51b5" } } })
theme = createTheme(theme, {
  components: { MuiAlert: { styleOverrides: { root: { marginBottom: theme.spacing(1) } } } },
})

const App = () => {
  const { error, isLoading } = useProject()

  if (isLoading) return <AppLoading />

  if (error) return <AppError error={String(error)} />

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {/*This global styling could be done in index.css, 
        but it would mean hardcoding bgcolor, so this is more flexible.*/}
        <GlobalStyles styles={{ "#root": { backgroundColor: backgroundColor } }} />
        <DisclaimerDialog />
        <CalculatePage />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const AppLoading = () => {
  return (
    <Box display="flex" justifyContent="center" minHeight="100vh" alignItems="center">
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress />
        <Typography>Loading...</Typography>
      </Box>
    </Box>
  )
}

type AppErrorProps = {
  error: string
}

const AppError = ({ error }: AppErrorProps) => {
  return (
    <Box display="flex" justifyContent="center" minHeight="100vh" alignItems="center">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography>Something went wrong. Please contact support.</Typography>
      </Box>
    </Box>
  )
}
export default App
