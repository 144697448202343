import * as A from "fp-ts/lib/Array"
import * as S from "fp-ts/lib/Set"
import { pipe } from "fp-ts/lib/function"
import { Eq, Ord } from "fp-ts/lib/string"
import { Crop } from "../../../types/types"

export type CropsByGroup = {
  groupName: string
  crops: Crop[]
}


export const groupCrops = (crops: Crop[]): CropsByGroup[] => {
  const groups = pipe(
    crops,
    A.map((crop) => crop.group),
    S.fromArray(Eq),
    S.toArray(Ord),
  )

  const groupedCrops: CropsByGroup[] = groups.map((group) => ({
    groupName: group,
    crops: crops.filter((crop) => crop.group === group),
  }))

  return groupedCrops
}
