import { Control } from "ol/control"
import ReactDOM from "react-dom"
import LayerButton from "./LayerButton"

/**
 * A custom control for toggling between Satellite and Map layers.
 * @class SatelliteMapToggle
 * @extends {ol.control.Control}
 */
export default class LayerSelectControl extends Control {
  /**
   * @constructor
   * @param onClickCallback
   */
  constructor(onClickCallback?: (isVisible: boolean) => void) {
    super({})

    const onSelectCallback = (activeLayer: string) => {
      if (onClickCallback !== undefined) {
        if (activeLayer === "Satellite") {
          onClickCallback(true)
        } else {
          onClickCallback(false)
        }
      }
    }
    const button = <LayerButton onSelectCallback={onSelectCallback} />

    const element = document.createElement("div")
    element.className = "ol-satellite ol-unselectable"
    ReactDOM.render(button, element)

    Control.call(this, { element: element })
  }
}
