import { Chip, Box, Tooltip, styled } from "@mui/material"
import { variabilityColors } from "../../../../colors"
import { ConstraintEntry } from "../../../../types/ConstraintEntry"

const DEFAULT_PRECISION = 2

type ChipFreqProps = {
  relFreq: number
  precision?: number
}

const StyledChip = styled(Chip)(() => ({
  "&.MuiChip-root": {
    verticalAlign: "top",
  },
}))

export const ChipFreq = (props: ChipFreqProps) => (
  <Tooltip title="Relative frequency ">
    <StyledChip size="small" label={(props.relFreq * 100).toFixed(props.precision ?? 0) + "%"} />
  </Tooltip>
)

type ChipMeanProps = {
  mean: number
  precision?: number
}

export const ChipMean = (props: ChipMeanProps) => (
  <Tooltip title="Average value of the data">
    <StyledChip size="small" label={"μ:" + props.mean.toFixed(props.precision ?? DEFAULT_PRECISION)} />
  </Tooltip>
)

type ChipMedianProps = {
  median: number
  precision?: number
}

export const ChipMedian = (props: ChipMedianProps) => (
  <Tooltip title="Median of the data">
    <StyledChip size="small" label={"med:" + props.median.toFixed(props.precision ?? DEFAULT_PRECISION)} />
  </Tooltip>
)

type ChipStdProps = {
  std: number
  precision?: number
}

export const ChipStd = (props: ChipStdProps) => (
  <Tooltip title="Standard deviation of the data">
    <StyledChip size="small" label={"σ:" + props.std.toFixed(props.precision ?? DEFAULT_PRECISION)} />
  </Tooltip>
)

type variability = "low" | "medium" | "high"

type ChipVariabilityProps = {
  variability: variability
}

export const ChipVariability = ({ variability }: ChipVariabilityProps) => {
  return (
    <Tooltip title="Variability of the data">
      <StyledChip
        style={{ backgroundColor: variabilityColors[variability] }}
        size="small"
        label={"variability: " + variability}
      />
    </Tooltip>
  )
}

type ChipSourceProps = {
  source: string
}

export const ChipSource = (props: ChipSourceProps) => (
  <Tooltip title="Data source">
    <StyledChip size="small" label={props.source} />
  </Tooltip>
)

type ChipReliabilityProps = {
  reliability: string[] // This represents the mode(s) of the data
}

export const ChipReliability = (props: ChipReliabilityProps) => {
  return (
    <>
      {props.reliability.map(
        (
          rel, // If there are multiple modes, there will be multiple reliability chips
        ) => (
          <Tooltip title="Reliability of the data" key={rel}>
            <StyledChip size="small" label={"reliability: " + rel.toLowerCase()} />
          </Tooltip>
        ),
      )}
    </>
  )
}

type MetadataProps = {
  entry: ConstraintEntry
  edited: boolean
}

export const Metadata = (props: MetadataProps) => {
  if (props?.entry?.user !== undefined) {
    return (
      <Box>
        <ChipSource source="User-entered data" />
      </Box>
    )
  } else if (props?.entry?.server && props.entry.server.stats) {
    switch (props.entry.server.type) {
      case "numerical": {
        const { mean, median, std, variability } = props.entry.server.stats
        const reliability = props.entry.server.reliability
        return (
          <Box>
            <ChipMean mean={mean} precision={2} />
            <ChipMedian median={median} precision={2} />
            <ChipStd std={std} precision={2} />
            <ChipVariability variability={variability} />
            <ChipReliability reliability={reliability} />
          </Box>
        )
      }
      case "ordinal": {
        const { mean, std, variability } = props.entry.server.stats
        const reliability = props.entry.server.reliability
        return (
          <Box>
            <ChipMean mean={mean} precision={2} />
            <ChipStd std={std} precision={2} />
            <ChipVariability variability={variability} />
            <ChipReliability reliability={reliability} />
          </Box>
        )
      }
      case "nominal": {
        const { relFreq, variability } = props.entry.server.stats
        const reliability = props.entry.server.reliability
        return (
          <Box>
            <ChipFreq relFreq={relFreq} precision={0} />
            <ChipVariability variability={variability} />
            <ChipReliability reliability={reliability} />
          </Box>
        )
      }
    }
  }
  return <></>
}
