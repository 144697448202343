import GeoJSON from "ol/format/GeoJSON"
import { useQuery } from "react-query"

import { fetchEnvironValueGeometry, GeometryIn } from "../../data"
import { useStoreActions, useStoreState } from "../../state/hooks"

/**
 * New Method to get polygon ev values instead of centroids.
 * Hook that wraps a fetchSampledPolygon query
 * and uses the polygon in the store.
 * @returns
 */
const useEnviroValuesGeom = () => {
  const polygon = useStoreState((state) => state.session.map.polygon)

  if (!polygon) throw new Error("useEnvironValuesGeom hook can only be used when a feature has been selected.")

  const featureGeoJson = new GeoJSON().writeGeometryObject(polygon) as GeometryIn
  const srid = 4326

  return _useEnviroValuesGeom(featureGeoJson, srid)
}

/**
 * Hook that wraps a fetchSampledPolygon query
 * @param feature feature in GeoJSON
 * @param srid spatial reference id
 * @returns
 */
const _useEnviroValuesGeom = (feature: GeometryIn, srid: number) => {
  const clearGeom = useStoreActions((actions) => actions.session.environmentalValues.clearGeom)
  const updateServerGeom = useStoreActions((actions) => actions.session.environmentalValues.updateDataGeom)

  const query = useQuery(
    ["environmental_values", feature, srid],
    async () => {
      const data = await fetchEnvironValueGeometry(feature, srid)
      clearGeom()
      updateServerGeom(data)

      return data
    },
    {
      enabled: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  return query
}

export default useEnviroValuesGeom
