import ListItemText from "@mui/material/ListItemText"
import { styled } from "@mui/material/styles"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Link from "@mui/material/Link"
import { useStoreState } from "../../state/hooks"
import { GlossaryEntry, sortConstraints } from "../../types/types"
import strings from "../../strings"

const PREFIX = "GlossaryList"

const classes = {
  extraDefinitions: `${PREFIX}-extraDefinitions`,
  root: `${PREFIX}-root`,
  additionalInfo: `${PREFIX}-additionalInfo`,
}

const Root = styled("div")(({ theme }) => ({
  [`&` /*&.{classes.root}*/]: {
    //For some reason, applying classes.root here doesn't style the component.
    // One symptom is the glossary takes up the entire screen width.
    maxWidth: 400,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },

  [`& .${classes.extraDefinitions}`]: {
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.additionalInfo}`]: {
    marginTop: theme.spacing(1),
  }
}))

const GlossaryList = () => {
  // Constraints are stored as a Map in the store state. Get the map's values as an array
  const constraints = useStoreState((state) => state.project.constraints)

  return (
    <Root className={classes.root}>
      {sortConstraints(constraints).map(([id, constraint]) => (
        <GlossaryItem key={id} name={constraint.name} glos={constraint.glossaryEntry ?? {}} />
      ))}
    </Root>
  )
}

const GlossaryItem = (props: { name: string; glos: GlossaryEntry }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <ListItemText>
          <Typography variant={"subtitle1"}>{props.name}</Typography>
        </ListItemText>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <Typography align={"left"} variant={"body2"}>
            {props.glos.shortDefinition}
          </Typography>

          <Typography className={classes.additionalInfo} align={"left"} variant={"body2"}>
            {strings.GENERAL_ELEMENTS.GLOSSARY_ADDITIONAL_INFO_TEXT} {props.glos.definition}
          </Typography>
          {props.glos.source && <Source glos={props.glos} />}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

const Source = (props: { glos: GlossaryEntry }) =>
  props.glos.sourceLink ? (
    <Link href={props.glos.sourceLink} target="_blank" variant={"caption"} underline="hover">
      {strings.GENERAL_ELEMENTS.GLOSSARY_SOURCE_TEXT} {props.glos.source}
    </Link>
  ) : (
    <Typography align={"left"} variant={"caption"}>
      {strings.GENERAL_ELEMENTS.GLOSSARY_SOURCE_TEXT} {props.glos.source}
    </Typography>
  )

export default GlossaryList
