import { Remote, wrap } from "comlink"
import { Calculator } from "../../workers/calculator.worker"

// This the new workers format with webpack 5.
const worker = new Worker(new URL("../../workers/calculator.worker.ts", import.meta.url))
const workerApi = wrap<Calculator>(worker)

export type WorkersModel = {
  calculateWorker: Remote<Calculator>
}

const model: WorkersModel = {
  calculateWorker: workerApi,
}

export default model
