import * as O from "fp-ts/lib/Option"
import * as E from "fp-ts/lib/Either"

import { Feature } from "ol"
import { Circle, Fill, Stroke, Style } from "ol/style"
import { Geometry, Point, Polygon } from "ol/geom"
import { getArea } from "ol/sphere"
import { Extent, getCenter } from "ol/extent"
import Projection from "ol/proj/Projection"
import VectorSource from "ol/source/Vector"

import kinks from "@turf/kinks"
import { polygon } from "@turf/helpers"

import { Score, getColor } from "../../../../types/Score"
import { CellWithReport, SampleReport } from "../../../../calculate"
import transformScale from "@turf/transform-scale"
import bbox from "@turf/bbox"
import { EnvironmentalValueGeometry } from "../../../../types/SampledData"
import geoJSON from "ol/format/GeoJSON"
import { backgroundColor, evColors } from "../../../../colors"

const scores = [Score.One, Score.Two, Score.Three, Score.Four, Score.Five]

/**
 * Returns the appropriate Style for a Feature, given a Score.
 *
 * @param score
 * @returns
 */
const getFeatureStyleFromScore = (score: Score): Style => {
  return new Style({
    image: new Circle({
      radius: 6,
      stroke: new Stroke({
        color: "#fff",
      }),
      fill: new Fill({
        color: getColor(score),
      }),
    }),
  })
}

// Precompute the styles for each score
const scoreToFeatureStyleMap = new Map(scores.map((score) => [score, getFeatureStyleFromScore(score)]))

/**
 * Returns an OpenLayers Feature that allows the cell report
 * to be visualised in the map component. The cell is identified
 * by a point geometry and includes some metadata.
 *
 *
 * @param cell CellWithReport
 * @returns Option<Feature>
 */
const cellToFeature = (cell: CellWithReport): O.Option<Feature<Geometry>> => {
  // Cells that do not have a geometry or any scores are ignored
  if (cell.geom === undefined || cell.ruleScores.length === 0) return O.none

  const feature = new Feature(new Point(cell.geom.coordinates))

  // Assume ruleScores is a (reverse) sorted by score...
  const maxScore = cell.ruleScores[0].score
  const limitingFactors: string[] = []

  // The limiting factors are the rules which have the same score as maxScore
  // ASSUMING the rules are sorted in reverse, we can do an early stop.
  for (const rs of cell.ruleScores) {
    if (rs.score === maxScore) limitingFactors.push(rs.id)
    else break
  }

  feature.setProperties({
    score: maxScore,
    ruleScores: cell.ruleScores,
    limitingFactors: limitingFactors,
    factStore: cell.factStore
  })

  feature.setStyle(scoreToFeatureStyleMap.get(maxScore)!)

  return O.some(feature)
}

/**
 * Returns an OpenLayers VectorSource that allows the sample report
 * to be visualised in the map component. The entire sample can then be
 * represented as a layer that includes a collection of point features,
 * representing individual cells.
 *
 * @param sample
 * @returns
 */
export const sampleToVectorSource = (sample: SampleReport): VectorSource<Geometry> => {
  const features = []

  for (const cell of sample.cells) {
    const featureOrNone = cellToFeature(cell)

    if (O.isSome(featureOrNone)) {
      const feature = featureOrNone.value
      features.push(feature)
    }
  }

  return new VectorSource({ features: features })
}
export const isPolygon = (geom: Geometry | undefined): geom is Polygon => {
  return geom?.getType() === "Polygon"
}
/**
 * @param geom the polygon
 * @param tolerance
 * @returns true if the polygon contains self-intersections
 */
const isKinked = (geom: Polygon, tolerance: number): boolean => {
  // Freehand selections may contain duplicate vertices.
  // Simplify the geometry, then check for kinks.
  // See: https://github.com/openlayers/openlayers/issues/6288
  const simpleGeom = geom.simplify(tolerance)

  const coords = isPolygon(simpleGeom) ? simpleGeom.getCoordinates() : geom.getCoordinates()
  const poly = polygon(coords)
  const kinkedPoly = kinks(poly)

  return kinkedPoly.features.length > 0
}

const KM2_TO_HA = 100
const KM2_TO_M2 = 1000000

// Min area is 5ha
const MIN_AREA_KM2 = 0.05
const MIN_AREA_HA = MIN_AREA_KM2 * KM2_TO_HA
// Max area is 1000ha
const MAX_AREA_KM2 = 10
const MAX_AREA_HA = MAX_AREA_KM2 * KM2_TO_HA

const DEFAULT_TOLERANCE = 0.0005

/**
 * Determine the center of the geometry's bounding box and intersect it
 * with the features to determine the underlying framework.
 * This means that geometries than span more than one framework are
 * NOT SUPPORTED.
 *
 * Assumes that there are no overlapping features and that FRAMEWORK
 * exists as a property of gbr.
 *
 * @param geom
 * @param regions
 * @param projection
 * @param resolution
 * @returns
 */
export const getGBRRegion = (
  geom: Geometry,
  regions: VectorSource<Geometry>,
  projection: Projection,
  resolution?: number,
): E.Either<string, [Polygon, string]> => {
  // Ensure the selection is a polygon
  if (!isPolygon(geom)) {
    return E.left("Selected area is not a valid polygon.")
  }

  // Check the area does not exceed max
  const area_m2 = getArea(geom, { projection })
  const area_km2 = area_m2 / KM2_TO_M2
  const area_ha = area_km2 * KM2_TO_HA

  if (area_km2 < MIN_AREA_KM2) {
    return E.left(
      "Selected area is too small (min area: " + MIN_AREA_HA + " ha, selected area: " + area_ha.toFixed(0) + " ha).",
    )
  }
  if (area_km2 > MAX_AREA_KM2) {
    return E.left(
      "Selected area is too large (max area: " + MAX_AREA_HA + " ha, selected area: " + area_ha.toFixed(0) + " ha).",
    )
  }

  // Check for self-intersections
  const tolerance = resolution ?? DEFAULT_TOLERANCE

  if (isKinked(geom, tolerance)) {
    return E.left("Selected area contains a self-intersection.")
  }

  // Use the center of the bounding box to extract the framework
  const extentCenter = getCenter(geom.getExtent())
  const features = regions.getFeaturesAtCoordinate(extentCenter)

  if (features.length > 1) {
    return E.left("Selected area intersects multiple features.")
  } else if (features.length === 0) {
    return E.left("Selected does not intersect any features")
  }

  const framework = features[0].getProperties()["FRAMEWORK"]

  if (framework === undefined) {
    return E.left("Feature has no property FRAMEWORK")
  } else if (typeof framework !== "string") {
    return E.left("Feature property FRAMEWORK contains an unexpected value")
  }

  return E.right([geom, framework])
}

export const getScaledExtent = (geometry: Polygon, optScale?: number): Extent => {
  const DEFAULT_SCALE = 1.2

  const scale = optScale ?? DEFAULT_SCALE
  const feature = polygon(geometry.getCoordinates())
  const transformedFeature = transformScale(feature, scale)
  const extent = bbox(transformedFeature) as Extent

  return extent
}

const mkStyledEvFeature = (ev: EnvironmentalValueGeometry) => {
  // Might need some code here to handle faulty ev values, ignore for now
  const reader = new geoJSON()
  const geometry = reader.readGeometry(ev.geom)
  const feature = new Feature({ geometry: geometry })
  feature.setProperties({
    metadata: ev.metadata,
    dataset: ev.dataset,
  })

  // Hardcoding the colours for now.
  // TODO: Maybe have a list of colours and then map ev datasets from project file to this colour list dynamically.
  const featureColor = getColorForEvDataset(ev.dataset)

  let lineWidth = 1
  if (ev.dataset === "Regulated vegetation - intersecting a watercourse") {
    lineWidth = 2.5
  }
  if (ev.dataset === "High ecological value waters - watercourse") {
    lineWidth = 4
  }

  const style = new Style({
    fill: new Fill({
      color: featureColor,
    }),
    stroke: new Stroke({
      color: featureColor,
      width: lineWidth,
    }),
  })
  feature.setStyle(style)

  return feature
}

export const getColorForEvDataset = (evDataset: string) => {
  let featureColor
  switch (evDataset) {
    case "High ecological significance wetlands":
      featureColor = evColors.evColor1
      break
    case "High ecological value waters - wetlands":
      featureColor = evColors.evColor2
      break
    case "High ecological value waters - watercourse":
      featureColor = evColors.evColor5
      break
    case "Regulated vegetation - category R GBR riverine":
      featureColor = evColors.evColor3
      break
    case "Regulated vegetation - intersecting a watercourse":
      featureColor = evColors.evColor4
      break
    case "Regulated vegetation - 100m from wetland":
      featureColor = evColors.evColor6
      break
    default:
      featureColor = backgroundColor
  }
  return featureColor
}

export const getColouredFeaturesFromEvs = (evs: EnvironmentalValueGeometry[] | null): any => {
  return evs?.map((ev) => {
    const feature = mkStyledEvFeature(ev)
    return feature
  })
}
