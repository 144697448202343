import "ol/ol.css"
import "ol-geocoder/dist/ol-geocoder.css"
import "./GBRMap.css"

import { Box, CircularProgress, Typography } from "@mui/material"
import { blueGrey } from "@mui/material/colors"
import { FullScreen, defaults as defaultControls } from "ol/control"
import { useRef, useState, useEffect, useMemo } from "react"

import { AlertCreate } from "../../../../state/model/alert"
import { mkOSMLayer, mkCadastralLayer, mkSatelliteLayer, mkGeocoder } from "./Layers"
import { useStoreActions } from "../../../../state/hooks"
import LayerSelectControl from "./LayerSelectControl"
import useMap, { useDraw, useGBRLayer } from "./useMap"
import useGbrRegions from "../../../../hooks/queries/useGbrRegions"

/**
 * Helper function for constructing an Alert error for the Map component
 * @param message
 * @returns
 */
const mkMapError = (message: string): AlertCreate => ({
  message: message,
  removeable: false,
  severity: "error",
  section: "map",
})

type Props = {
  undo?: number
}

const GBRMap = (props: Props) => {
  const { undo } = props

  const mapRef = useRef<HTMLDivElement>(null)

  const { map } = useMap()
  const { isLoading, isError, data: gbr } = useGbrRegions()
  const gbrLayer = useGBRLayer()
  const { draw, drawLayer, error: drawError, undo: undoDraw } = useDraw(map, gbr)

  const [isSatelliteVisible, setSatelliteVisible] = useState(true)

  const { osmLayer, cadastralLayer, satelliteLayer, geocoder } = useMemo(() => {
    const osmLayer = mkOSMLayer()
    const cadastralLayer = mkCadastralLayer()
    const satelliteLayer = mkSatelliteLayer()
    const geocoder = mkGeocoder()
    return { osmLayer, cadastralLayer, satelliteLayer, geocoder }
  }, [])

  const addAlert = useStoreActions((actions) => actions.alert.addAlert)

  satelliteLayer.setVisible(isSatelliteVisible)

  useEffect(() => {
    if (drawError) {
      addAlert(mkMapError(drawError))
    }
  }, [drawError, addAlert])

  /**
   * On component mount
   */
  useEffect(() => {
    // Layers

    if (mapRef.current === null) return
    if (gbrLayer === undefined) return

    map.setTarget(mapRef.current)

    const layers = [osmLayer, satelliteLayer, cadastralLayer, drawLayer, gbrLayer]

    const satelliteVisible = new LayerSelectControl(setSatelliteVisible)

    const controls = defaultControls({ attributionOptions: { collapsible: true, collapsed: false } }).extend([
      new FullScreen(),
      geocoder,
      satelliteVisible,
    ])

    layers.forEach((layer) => map.addLayer(layer))
    controls.forEach((control) => map.addControl(control))
    map.addInteraction(draw)

    return () => {
      layers.forEach((layer) => map.removeLayer(layer))
      controls.forEach((control) => map.removeControl(control))
      map.removeInteraction(draw)
    }
  }, [cadastralLayer, draw, drawLayer, gbrLayer, geocoder, map, osmLayer, satelliteLayer])

  /**
   * On undo listener.
   */
  useEffect(() => {
    if (!undo) return
    undoDraw()
  }, [undo, undoDraw])

  if (isLoading || isError) {
    return (
      <Box
        bgcolor={blueGrey[50]}
        display="flex"
        flexDirection="column"
        minHeight="500px"
        justifyContent="center"
        alignItems="center"
      >
        {isLoading ? <CircularProgress /> : null}
        <Typography>
          {isLoading ? "Loading map..." : null}
          {isError ? "Error loading map. Please contact support." : null}
        </Typography>
      </Box>
    )
  }

  return <div ref={mapRef} className="ol-map"></div>
}

export default GBRMap
