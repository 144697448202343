import * as E from "fp-ts/lib/Either"
import * as ol from "ol"

import { Geometry } from "ol/geom"
import { DrawEvent } from "ol/interaction/Draw"
import { fromLonLat, get } from "ol/proj"
import { Vector } from "ol/source"
import { useCallback, useEffect, useMemo, useState } from "react"
import useGbrRegions from "../../../../hooks/queries/useGbrRegions"
import { useStoreActions, useStoreState } from "../../../../state/hooks"
import { mkDraw, mkGBRLayer } from "./Layers"
import { getGBRRegion, getScaledExtent, isPolygon } from "./utils"

const DEFAULT_CENTER = fromLonLat([147.0252, -19.46969], "EPSG:4326")
const DEFAULT_ZOOM = 5
const DEFAULT_PROJ = get("EPSG:4326")

/**
 * Hook that creates an ol.Map and performs cleanup tasks when unmounted
 * @returns
 */
const useMap = () => {
  const mapAndCleanup = useMemo(() => makeMapAndCleanup(), [])

  useEffect(() => {
    const { cleanup } = mapAndCleanup

    return () => {
      cleanup()
    }
  }, [mapAndCleanup])

  return mapAndCleanup
}

/**
 * @returns An ol.Map and a cleanup function
 */
const makeMapAndCleanup = () => {
  const options = {
    view: new ol.View({
      projection: DEFAULT_PROJ,
      zoom: DEFAULT_ZOOM,
      center: DEFAULT_CENTER,
    }),
    layers: [],
    controls: [],
    overlays: [],
  }
  const map = new ol.Map(options)

  const cleanup = () => map.setTarget(undefined)

  return { map, cleanup }
}

export const useGBRLayer = () => {
  const { data } = useGbrRegions()
  const layer = useMemo(() => data ? mkGBRLayer(data) : undefined, [data])

  return layer
}

export const useDraw = (map: ol.Map, regions?: Vector<Geometry>) => {
  const [draw, drawLayer, drawSource] = useMemo(() => mkDraw(), [])
  const [error, setError] = useState<string>()

  const feature = useStoreState((state) => state.session.map.polygon)

  const setFeature = useStoreActions((actions) => actions.session.map.setPolygon)
  const setFramework = useStoreActions((actions) => actions.session.map.setFramework)
  const removeFeature = useStoreActions((actions) => actions.session.map.removePolygon)
  const removeFramework = useStoreActions((actions) => actions.session.map.removeFramework)
  const setSelectionState = useStoreActions((actions) => actions.session.map.setSelectionState)

  const onDrawStart = useCallback(() => {
    setSelectionState("in progress")
  }, [setSelectionState])

  const onDrawEnd = useCallback(
    (e: DrawEvent) => {
      const geometry = e.feature.getGeometry()

      if (!geometry || !isPolygon(geometry)) {
        setSelectionState("completed")
        setError("Selection does not contain a valid geometry")
        return
      }

      if (!map || !regions) throw new Error("")
      const errorOrFeatureAndFramework = getGBRRegion(
        geometry,
        regions,
        map.getView().getProjection(),
        map.getView().getResolution(),
      )

      if (E.isLeft(errorOrFeatureAndFramework)) {
        // Error case
        setError(errorOrFeatureAndFramework.left)
      } else {
        // Success case
        const [feature, framework] = errorOrFeatureAndFramework.right
        setFeature(feature)
        setFramework(framework)
      }

      // Disable draw interaction
      draw.setActive(false)
      setSelectionState("completed")

      // Center view to fit geometry
      const extent = getScaledExtent(geometry)
      map.getView().fit(extent)
    },
    [draw, map, regions, setFeature, setFramework, setSelectionState],
  )

  const undo = useCallback(() => {
    if (!draw.getActive()) {
      drawSource.clear()
      draw.setActive(true)
      removeFeature()
      removeFramework()
      setSelectionState("not started")
    } else {
      draw.removeLastPoint()
    }
  }, [draw, drawSource, removeFeature, removeFramework, setSelectionState])

  useEffect(() => {
    if (!feature) return

    // Restore the feature
    drawSource.addFeature(new ol.Feature({ geometry: feature }))
    draw.setActive(false)
    setSelectionState("completed")

    // Attempt to restore the view
    const extent = getScaledExtent(feature)
    map.getView().fit(extent)
  }, [draw, drawSource, feature, map, setSelectionState])

  useEffect(() => {
    // On hook mount...
    // Register event handlers
    draw.on("drawstart", onDrawStart)
    draw.on("drawend", onDrawEnd)

    return () => {
      // Unregister event handlers
      draw.un("drawstart", onDrawStart)
      draw.un("drawend", onDrawEnd)
    }
  }, [draw, onDrawEnd, onDrawStart])

  return { draw, drawLayer, drawSource, error, undo }
}

export default useMap
