import React from "react"
import { Constraint } from "../../../../types/types"
import AttributeCategorical from "./AttributeCategorical"
import AttributeNumerical from "./AttributeNumerical"

type Props = {
  constraint: Constraint
  disabled?: boolean
}

const AttributeInput = ({ constraint, disabled }: Props) => {
  const renderAttribute = () => {
    switch (constraint.tag) {
      case "categorical":
        return <AttributeCategorical constraint={constraint} disabled={disabled ?? false} />
      case "continuous":
        return <AttributeNumerical constraint={constraint} disabled={disabled ?? false} />
    }
  }
  return renderAttribute()
}

export default React.memo(AttributeInput)
