import { Constraint, Rule } from "./types"
import { pipe } from "fp-ts/lib/function"
import * as A from "fp-ts/lib/Array"
import * as M from "fp-ts/lib/Map"
import * as S from "fp-ts/lib/Set"
import { Eq, Ord } from "fp-ts/lib/string"
import { SampleReport } from "../calculate"

export type ConstraintReport = {
  name: string
  value: string
  source: string
  metadata: Record<string, string>
  reliability: string[] | undefined
}

export type RuleReport = {
  name: string
  constraints: string[]
}

export type CropReport = {
  name: string
  sampleReport: SampleReport
}

/**
 * @param rule
 * @returns The unique constraint ids referenced by a Rule.
 */
const constraintIdsFromRule = (rule: Rule): string[] => {
  return pipe(
    // for each definition...
    rule.definition,
    // get the list of constraint ids...
    A.map(({ constraints }) => constraints),
    // at this point we have a list of list of constraint ids. concatenate the lists
    A.flatten,
    // we are interested in unique constraint ids
    S.fromArray(Eq),
    S.toArray(Ord),
  )
}

/**
 * @param rule
 * @param constraints
 * @returns The unique Constraints referenced by a Rule
 */
const constraintsFromRule = (rule: Rule, constraints: Map<string, Constraint>): Constraint[] => {
  const constraintIds = constraintIdsFromRule(rule)

  return pipe(
    // for each constraint id...
    constraintIds,
    // find the corresponding constraint in the map
    A.map((c) => M.lookup(Eq)(c)(constraints)),
    // discard nones
    A.compact,
  )
}

/**
 * @param rule
 * @param constraints
 * @returns A summary of the Rule
 */
export const mkRuleReport = (rule: Rule, constraints: Map<string, Constraint>): RuleReport => {
  const constraintNames = pipe(
    constraintsFromRule(rule, constraints),
    A.map((constraint) => constraint.name),
  )
  return {
    name: rule.name,
    constraints: constraintNames,
  }
}

/**
 * @param rules
 * @param constraints
 * @returns A summary of the Rules
 */
export const mkRuleReports = (rules: Rule[], constraints: Map<string, Constraint>): RuleReport[] => {
  return pipe(
    rules,
    A.map((rule) => mkRuleReport(rule, constraints)),
  )
}

/**
 *
 * @param constraint
 * @param value
 * @param source
 * @param metadata
 * @returns A summary of the Constraint
 */
export const mkConstraintReport = (
  constraint: Constraint,
  value: string,
  source: string,
  metadata: Record<string, string>,
  reliability: string[] | undefined,
): ConstraintReport => {
  return {
    name: constraint.name,
    value,
    source,
    metadata,
    reliability,
  }
}
