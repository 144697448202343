import { action, Action } from "easy-peasy"
import { EnvironmentalValueGeometry } from "../../../types/SampledData"

export type EnviroValuesModel = {
  // State

  /**
   * An array of the data that the attribute service returned.
   */
  serverDataGeom: EnvironmentalValueGeometry[] | null
  datasetNamesGeom: string[]

  // Actions

  /**
   * Updates the server data.
   */
  updateDataGeom: Action<EnviroValuesModel, EnvironmentalValueGeometry[]>
  clearGeom: Action<EnviroValuesModel>
}

const enviroValuesModel: EnviroValuesModel = {
  serverDataGeom: null,
  datasetNamesGeom: [],
  updateDataGeom: action((store, payload) => {
    store.serverDataGeom = payload
    store.datasetNamesGeom = Array.from(new Set(store.serverDataGeom.map((item) => item.dataset)))
  }),
  clearGeom: action((store) => {
    store.serverDataGeom = null
  }),
}

export default enviroValuesModel
