import { useState } from "react"

type Step = {
  id: number
  label: string
  elem: () => JSX.Element
}

const useStep = (steps: Step[]) => {
  const [activeStepIdx, setActiveStepIdx] = useState(0)
  const activeStep: Step = steps[activeStepIdx]

  const canPrev = ((): boolean => {
    if (activeStepIdx <= 0) return false
    return true
  })()

  const canNext = ((): boolean => {
    if (activeStepIdx >= steps.length - 1) return false
    return true
  })()

  const prevStep = () => {
    if (!canPrev) return
    setActiveStepIdx((idx) => idx - 1)
  }

  const nextStep = () => {
    if (!canNext) return
    setActiveStepIdx((idx) => idx + 1)
  }

  const isStartStep = activeStepIdx === 0
  const isFinalStep = activeStepIdx === steps.length - 1
  const isCompleted = (stepIdx: number) => activeStepIdx > stepIdx

  return { activeStepIdx, activeStep, prevStep, nextStep, isStartStep, isFinalStep, isCompleted, canPrev, canNext }
}

export default useStep
