/*

This file should contain all strings that are used for static elements in the app.

Many strings are not handled here, rather handled in the project file. This includes:
- Title
- Project version numbers
- Attributes
- Attribute glossaries

*/

export const strings = {
  GENERAL_ELEMENTS: {
    PREV_STEP_BUTTON: "Prev",
    NEXT_STEP_BUTTON: "Next",
    UNDO_BUTTON: "Undo",
    SELECT_ALL_BUTTON: "Select All",
    UNSELECT_ALL_BUTTON: "Unselect All",
    CLEAR_SELECT_BUTTON: "Clear Selection",

    GLOSSARY_TEXT: "Glossary",
    GLOSSARY_CLOSE_TEXT: "Close Glossary",
    GLOSSARY_ADDITIONAL_INFO_TEXT: "Additional Information: ",
    GLOSSARY_SOURCE_TEXT: "Source of autofilled data: ",
  },

  DISCLAIMERS: {
    DIALOG_TITLE: "Disclaimer",
    DIALOG_SECTION_1:
      "This tool is designed to be indicative of suitable areas for growing specific crops and to assist pre-feasibility studies to assess the need for further analysis. Any area of interest should be analysed in more detail by appropriate experts to confirm crop suitability.",
    DIALOG_SECTION_2:
      "While every care is taken to ensure the accuracy of these datasets, all data custodians and/or the State of Queensland makes no representations or warranties about its accuracy, reliability, completeness or suitability for any particular purpose. The State of Queensland disclaims all responsibility and all liability (including without limitation, liability in negligence) for all expenses, losses, damages (including indirect or consequential damage) and costs to which you might incur as a result of the data being inaccurate or incomplete in any way and for any reason.",
    DIALOG_SECTION_3: "For more information please visit ",
    DIALOG_LINK: "https://www.qld.gov.au/legal/disclaimer",
    DISAGREE_BUTTON: "Disagree",
    DISAGREE_LINK: "https://qld.gov.au",
    AGREE_BUTTON: "Agree",
  },

  REGION_STEP: {
    STEP_TITLE: "Map",
    PAGE_TITLE: "Select the area of land",
  },

  MAP_ELEMENTS: {
    NOT_STARTED_HELP_MESSAGE: "Begin drawing by zooming-in and clicking on any point on the map (Reef catchments)",
    IN_PROGRESS_HELP_MESSAGE:
      "To finish drawing, close the polygon or click the last point. Press UNDO to remove the last point of a polygon",
    FREEHAND_POLYGON_HELP_MESSAGE: "Click and drag on an area. Press UNDO to clear the selection.",
    ACTIVE_FRAMEWORK_MESSAGE: "Active Framework: ",
  },

  ATTRIBUTES_STEP: {
    STEP_TITLE: "Attributes",
    LIMITATIONS_TITLE: "Select limitations to consider",
    LIMITATIONS_SUBTITLE: "Limitations relating directly to water quality risk are selected by default.",

    ATTRIBUTES_TITLE: "Soil and land attributes",
    ATTRIBUTES_SUBTITLE: "Autofilled from available data. Overwrite value if you have more locally relevant data.",

    AUTOFILL_FETCH_FAILED: "Failed to fetch, click Autofill to try again.",
  },

  CROPS_STEP: {
    STEP_TITLE: "Crops",
    PAGE_TITLE: "Select the crops of interest",
  },

  RESULTS_STEP: {
    STEP_TITLE: "Results",
    PAGE_TITLE: "Results",

    CALCULATE_BUTTON: "Calculate",

    EV_LEGEND_HEADER: "Environmental Values Legend",
    SELECTED_CELL_HEADER: "Selected Cell Details",
    OVERALL_ASSESSMENT_HEADER: "Overall Assessed Risks",

    CALCS_NOT_RUN: "You need to run the calculations first.",
    VIEW_CELL_DATA_INSTRUCTIONS:
      "To view individual cell data, select a crop from the table below then select a cell from the map above.",
    RESULT_TABLE_CELL_INFO: "Click on a crop below to show risk across selected area on the map.",
  },

  REPORT: {
    LAT_LONG_TEXT: "Latitude, Longitude: ",

    TITLE_PAGE_TITLE: "Reef Water Quality Land Limitation checker",
    TITLE_PAGE_SUBTITLE: "Pre-feasibility Report",

    NOTICE: {
      DISCLAIMER_HEADER: "Disclaimer",
      DISCLAIMER_TEXT: [
        "The materials available on or through this website are distributed by the Queensland Government as an information source only.",
        "To the maximum extent permitted by law, the State of Queensland makes no statement, representation, or warranty about the quality, accuracy, context, completeness, availability or suitability for any purpose of, and you should not rely on, any materials available on or through this website.",
        "Despite our best efforts, the State of Queensland makes no warranties that the materials available on or through this website are free of infection by computer viruses or other contamination, to the maximum extent permitted by law.",
        "The Queensland Government disclaims, to the maximum extent permitted by law, all responsibility and all liability (including without limitation, liability in negligence) for all expenses, losses, damages and costs you or any other person might incur for any reason including as a result of the materials available on or through this website being in any way inaccurate, out of context, incomplete, unavailable, not up to date or unsuitable for any purpose.",
        "A user of this website who uses the links provided to another Queensland Government agency’s website and material available on or through that other website acknowledges that the disclaimer and any terms of use, including licence terms, set out on the other agency’s website govern the use which may be made of that material.",
      ],

      LICENSE_HEADER: "License",
      LICENSE_TEXT: [
        "This work by the State of Queensland (Department of Environment and Science) is licensed under CC BY 4.0. To view a copy of this license, visit http://creativecommons.org/licenses/by/4.0/.",
      ],

      ACKNOWLEDGE_HEADER: "Acknowledgements",
      ACKNOWLEDGE_TEXT: [
        "Includes material",
        "© OpenStreetMap contributors. © State of Queensland (Department of Resources). © Planet Labs Netherlands B.V. reproduced under licence from Planet and Geoplex",
        "All rights reserved, 2023.",
      ],
    },

    ATTRIBUTES: {
      LIMITATION_SERVER_HEADER: "Limitations considered with server supplied data",
      LIMITATION_USER_HEADER: "Limitations considered with user supplied data",

      LIMITATION_TABLE_LIMIT_HEADER: "Limitation name",
      LIMITATION_TABLE_ATTR_HEADER: "Attributes assessed",

      ATTRIBUTE_TABLE_TITLE: "Attributes",

      ATTRIBUTE_TABLE_ATTR_HEADER: "Attribute name",
      ATTRIBUTE_TABLE_VALUE_HEADER: "Attribute value",
      ATTRIBUTE_TABLE_METADATA_HEADER: "Metadata",
      ATTRIBUTE_TABLE_SOURCE_HEADER: "Source",
    },

    RESULTS: {
      SERVER_DATA_HEADER: "Results for reef water quality risk (server supplied data)",
      USER_DATA_HEADER: "Results for reef water quality risk (user supplied data)",

      TABLE_LIMITATION_HEADER: "Limitation",
      TABLE_RISK_HEADER: "Risk",
      TABLE_MEAN_HEADER: "Mean",
      TABLE_STDDEV_HEADER: "Std Dev.",
    },

    ENVIRO_VALUES: {
      PAGE_TITLE: "Environmental Values",
      INFO_TEXT: "The following environmental values were found within a 3 kilometre radius of the selected area.",

      TABLE_TITLE: "Key",
      TABLE_EV_HEADER: "Environmental value",
      TABLE_COLOUR_HEADER: "Colour",
    },
  },
}

export default strings
