import { ask } from "@bitproxima/decision-tree"
import { DecisionTree, FactStore } from "@bitproxima/decision-tree/dist/types"
import * as O from "fp-ts/lib/Option"
import { CropReport } from "./Report"
import { Score } from "./Score"

export interface Site {
  name: string
  image?: string
  coordinates?: [number, number]
  evCoordinates?: [number, number]
  evImage?: string
}

export type CropResult = {
  crop: Crop
  results: { rule: Rule; score: Score }[]
}

export type LimitingFactor = {
  score: Score
  rules: string[]
}

export type RuleScores = {
  id: string //rule id
  score: Score
}[]

/**
 * Given a set of limitations for a crop, compute the limiting factor.
 * This is the max suitability score and a list of rules that make the crop unsuitable.
 * @param result crop limitations
 * @returns the limiting factor
 */
export const calculateLimitingFactor = (result: CropReport): LimitingFactor | null => {
  const sortedResults = result.sampleReport.ruleScoreSummaries.sort((a, b) => a.summary.score - b.summary.score)

  if (sortedResults.length) {
    // There is more than one result.
    const maxScore = sortedResults[sortedResults.length - 1].summary.score
    const rules = sortedResults.filter((r) => r.summary.score === maxScore).map((r) => r.rule)

    return { score: maxScore, rules }
  }

  return null
}

export type CropConstraint = CategoricalConstraint & {
  name: "Crop"
}

export const isCropConstraint = (constraint: Constraint): constraint is CropConstraint => {
  return constraint.tag === "categorical" && constraint.name === "Crop"
}

export type SiteConstraint = CategoricalConstraint & {
  name: "Site"
}

export const isSiteConstraint = (constraint: Constraint): constraint is SiteConstraint => {
  return constraint.tag === "categorical" && constraint.name === "Site"
}

export type Reliability = string

// Shared with Rule Calculator

///////////////////////////////////////////////////////////////

export type Range = {
  lower: number
  upper: number
  lowerInclusive: boolean
  upperInclusive: boolean
}

export const stringToRange = (input: string): Range | undefined => {
  /**
   * Matches AB,CD where
   * A is either [ or ( denoting lower inclusive or exclusive, respectively
   * B is either the string `null` or a signed decimal value
   * C is either the string `null` or a signed decimal value
   * D is either ] or ) denoting right inclusvive or exclusive, respectively
   *
   * Examples: [1,2] (-.1,3) (,5] [100,)
   */
  const matchesFormat = input.match(/^([[(])(-Infinity|null|[-+]?\d*\.?\d*)?,(Infinity|null|[-+]?\d*\.?\d*)?([\])])$/)

  if (matchesFormat) {
    const [, lowerInclusive, lhs, rhs, upperInclusive] = matchesFormat

    const lhsNumber = lhs === "null" ? -Infinity : parseFloat(lhs)
    const rhsNumber = rhs === "null" ? Infinity : parseFloat(rhs)

    return {
      lower: isNaN(lhsNumber) ? -Infinity : lhsNumber,
      upper: isNaN(rhsNumber) ? Infinity : rhsNumber,
      lowerInclusive: lowerInclusive === "[",
      upperInclusive: upperInclusive === "]",
    }
  } else {
    return undefined
  }
}

export const rangeToString = (range: Range): string => {
  const { lower, upper } = range
  const leftBound = range.lowerInclusive ? "[" : "("
  const rightBound = range.upperInclusive ? "]" : ")"
  return leftBound + lower + "," + upper + rightBound
}

export const inRange = (value: number, range: Range): boolean => {
  const lower = range.lower ?? -Infinity
  const upper = range.upper ?? +Infinity
  const boundedByLower = range.lowerInclusive ? lower <= value : lower < value
  const boundedByUpper = range.upperInclusive ? value <= upper : value < upper
  return boundedByLower && boundedByUpper
}

///////////////////////////////////////////////////////////////

export type Source = { kind: "COG"; url: string } | { kind: "attribute-service" }

export type GlossaryEntry = {
  shortDefinition?: string
  definition?: string
  source?: string
  sourceLink?: string
}

export type BaseConstraint = {
  id: string
  name: string
  description?: string
  priority?: number
  glossaryEntry?: GlossaryEntry
  dataSource?: Source
  ctMeasure?: CentralTendencyMeasure
}

export type CategoricalConstraint = BaseConstraint & {
  categories: string[]
  tag: "categorical"
}

export type ContinuousConstraint = BaseConstraint & {
  domain?: Range
  tag: "continuous"
}

export type Constraint = CategoricalConstraint | ContinuousConstraint

export const sortConstraints = (constraints: Map<string, Constraint>) => {
  // TODO consider using the mapsort function to make this faster.
  // And easier to read for fp warriors.
  // Since there are less than 50 constraints (as of Nov 2021),
  // and the preprocessing is minor, this will almost certainly not be necessary.
  return Array.from(constraints).sort(function ([, a], [, b]) {
    let textA = a.name.toUpperCase()
    let textB = b.name.toUpperCase()
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })
}

export type Definition = {
  constraints: string[]
  decisionTree: DecisionTree
}

export type Rule = {
  id: string
  name: string
  description?: string
  definition: Definition[]
}

export type Crop = {
  id: string
  name: string
  group: string
}

export type Project = {
  name: string
  version: string
  description: string
  crops: Crop[]
  constraints: Constraint[]
  rules: Rule[]
}

export type CentralTendencyMeasure = "mean" | "median" | "mode"

export type ProjectFile = Omit<Project, "rules"> & {
  rules: (Omit<Rule, "definition"> & { definitionFile: string })[]
}

/**
 * Returns a result for this rule given a collection of facts.
 * Evaluates each tree in depth-first order.
 * Where there are multiple results given by multiple decision trees,
 * prefer the first result.
 *
 * @param factStore a collection of facts
 * @param lookupRule
 * @returns
 */
export const lookup = (factStore: FactStore, rule: Rule): O.Option<string> => {
  const decisionTrees = []

  for (const definition of rule.definition) {
    const { constraints, decisionTree } = definition
    let noUndefinedFacts = true
    for (let i = 0; i < constraints.length && noUndefinedFacts; i++) {
      const constraint = constraints[i]
      const value = factStore.get(constraint)
      if (value === undefined) noUndefinedFacts = false
    }

    if (noUndefinedFacts) decisionTrees.push(decisionTree)
  }

  for (const tree of decisionTrees) {
    const optionResult = ask(factStore, tree)
    if (O.isSome(optionResult)) {
      const result = optionResult.value
      return O.some(result.value)
    }
  }
  return O.none
}
