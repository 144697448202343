import * as O from "fp-ts/lib/Option"
import { FactStore } from "@bitproxima/decision-tree/dist/types"
import { pipe } from "fp-ts/lib/function"
import { mean, std } from "./stats"
import { Constraint, lookup, Rule, RuleScores } from "./types/types"
import { Score, parse } from "./types/Score"
import { SampledData } from "./types/SampledData"

/**
 * Minimal representation of a (grid) cell.
 */
export type Cell = {
  resolution_m?: number
  geom?: {
    type: "Point"
    coordinates: number[]
  }
}

/**
 * A grid cell with facts about the cell.
 * Used to create a CellWithReport.
 */
export type CellWithFacts = Cell & {
  factStore: FactStore
}

/**
 * A grid cell with a report, and facts about the cell.
 */
export type CellWithReport = CellWithFacts & {
  ruleScores: RuleScores
}

/**
 * Summary statistics for a collection of results for a rule.
 */
type ResultSummary = {
  score: Score
  stats?: {
    mean: number
    std: number
    n: number
  }
}

export type SampleReport = {
  cells: CellWithReport[]
  ruleScoreSummaries: {
    rule: string
    summary: ResultSummary
  }[]
}

/**
 * Run the rules with the facts of a cell to produce a CellReport
 * 
 * @param rules
 * @param cellData 
 * @returns 
 */
const calculateCellScore = (rules: Rule[], cellData: CellWithFacts): CellWithReport => {
  const { factStore } = cellData
  const ruleScores = []

  for (const rule of rules) {
    const scoreAsString = lookup(factStore, rule)
    const scoreOrNone = pipe(scoreAsString, O.map(parse), O.compact)
    if (O.isSome(scoreOrNone)) {
      const score = scoreOrNone.value
      ruleScores.push({ id: rule.id, score: score })
    }
  }

  // sort the results by score in decreasing order
  ruleScores.sort((a, b) => b.score - a.score)

  return { ...cellData, ruleScores }
}

/**
 * Run the rules against each cell to produce a SampleReport
 * 
 * @param rules 
 * @param cellDatas 
 * @returns 
 */
export const calculateSampleScore = (rules: Rule[], cellDatas: CellWithFacts[]): SampleReport => {
  const results = []
  const ruleScores: Record<string, Score[]> = {}

  for (const cellData of cellDatas) {
    const result = calculateCellScore(rules, cellData)
    results.push(result)

    result.ruleScores.forEach(({ id, score }) => {
      const scores = ruleScores[id] ?? []
      scores.push(score)
      ruleScores[id] = scores
    })
  }

  const summaries = []

  for (const ruleId in ruleScores) {
    const scores = ruleScores[ruleId]
    const rule = rules.find((rule) => rule.id === ruleId)!

    if (scores.length === 0) continue
    else if (scores.length === 1) summaries.push({ rule: rule.name, summary: { score: scores[0] } })
    else {
      const scoresMean = mean(scores)
      const scoresStd = std(scores)
      const score = (parse(Math.round(scoresMean)) as O.Some<Score>).value

      summaries.push({
        rule: rule.name,
        summary: {
          score: score,
          stats: {
            mean: scoresMean,
            std: scoresStd,
            n: scores.length,
          },
        },
      })
    }
  }

  return {
    cells: results,
    ruleScoreSummaries: summaries,
  }
}

/**
 * Use SampledData, overriden with user data to create CellsWithFacts.
 * 
 * @param constraints 
 * @param userData
 * @param sampledData
 * @returns
 */
export const mkCellsWithFacts = (
  constraints: Constraint[],
  userData: Record<string, string>,
  sampledData: SampledData,
): CellWithFacts[] => {
  const result = []
  const constraintTypes = new Map(constraints.map((c) => [c.id, c.tag]))
  const { resolution_m, samples } = sampledData

  for (const sample of samples) {
    const factStore = new Map()

    recordToFactStore(constraintTypes, sample.values, factStore)
    recordToFactStore(constraintTypes, userData, factStore)

    const cellData = {
      geom: sample.geom,
      resolution_m,
      factStore,
    }

    result.push(cellData)
  }

  return result
}

/**
 * Mutates factStore to include all entries of data.
 * The data is parsed to the appropriate type.
 * If the data is expected to be a number, then it is parsed as a number.
 * 
 * @param constraintTypes
 * @param data
 * @param factStore
 */
const recordToFactStore = (
  constraintTypes: Map<string, "categorical" | "continuous">,
  data: Record<string, string | null>,
  factStore: FactStore,
) => {
  Object.entries(data).forEach(([key, value]) => {
    if (value === null) return
    if (constraintTypes.get(key) === "continuous") {
      const valueAsFloat = parseFloat(value)
      if (isNaN(valueAsFloat)) return
      factStore.set(key, valueAsFloat)
    } else {
      factStore.set(key, value)
    }
  })
}
