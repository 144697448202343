import { Checkbox, FormControlLabel } from "@mui/material"
import React from "react"
import { useStoreActions, useStoreState } from "../../../state/hooks"
import { Crop } from "../../../types/types"

type Props = {
  crop: Crop
}

const CropItem = (props: Props) => {
  const { crop } = props

  const selectedCrops = useStoreState((state) => state.session.crops.crops)
  const add = useStoreActions((actions) => actions.session.crops.add)
  const remove = useStoreActions((actions) => actions.session.crops.remove)

  const selected = selectedCrops.has(crop.id)

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) add(crop.id)
    else remove(crop.id)
  }

  return (
    <FormControlLabel
      key={crop.id}
      control={<Checkbox checked={selected} onChange={handleOnChange} name={crop.id} />}
      label={crop.name}
    />
  )
}

export default React.memo(CropItem)
