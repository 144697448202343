import { computed, Computed } from "easy-peasy"
import * as A from "fp-ts/lib/Array"
import * as M from "fp-ts/lib/Map"
import * as S from "fp-ts/lib/Set"
import * as T from "fp-ts/lib/Tuple"
import { flow, pipe } from "fp-ts/lib/function"
import { StoreModel } from ".."
import { Constraint, Crop, Rule } from "../../../types/types"
import mapModel, { MapModel } from "./map"
import constraintsModel, { ConstraintsModel } from "./constraints"
import cropsModel, { CropsModel } from "./crops"
import rulesModel, { SelectedRulesModel } from "./rules"
import { Eq, Ord } from "fp-ts/lib/string"
import reportModel, { ReportModel } from "./report"
import { ConstraintEntry } from "../../../types/ConstraintEntry"
import enviroValuesModel, { EnviroValuesModel } from "./enviroValues"

export type SelectedConstraint = {
  constraint: Constraint
  entry: ConstraintEntry
}

export type SelectedConstraintWithRules = SelectedConstraint & {
  rules: Rule[]
}

export type SessionModel = {
  // State
  map: MapModel
  rules: SelectedRulesModel
  constraints: ConstraintsModel
  crops: CropsModel
  report: ReportModel
  environmentalValues: EnviroValuesModel

  // Computed
  /**
   * A list of rules that are currently selected
   */
  selectedRules: Computed<SessionModel, Rule[], StoreModel>

  /**
   * A list of tuples representing the Constraint and its corresponding value.
   * Constraints that do not have a value are not included.
   */
  selectedConstraints: Computed<SessionModel, Constraint[], StoreModel>

  selectedCrops: Computed<SessionModel, Crop[], StoreModel>
}

const sessionModel: SessionModel = {
  map: mapModel,
  rules: rulesModel,
  constraints: constraintsModel,
  crops: cropsModel,
  report: reportModel,
  environmentalValues: enviroValuesModel,
  selectedRules: computed(
    [(state) => state.rules.selectedRules, (_, storeState) => storeState.project.rules],
    (ids, rules) => Array.from(ids).map((id) => rules.get(id)!),
  ),
  selectedCrops: computed([(state) => state.crops.crops, (_, storeState) => storeState.project.crops], (ids, crops) =>
    pipe(
      ids,
      S.toArray(Ord),
      A.map((cropId) => M.lookup(Eq)(cropId)(crops)),
      A.compact,
    ),
  ),
  selectedConstraints: computed(
    [(state, _) => state.constraints.entries, (_, storeState) => storeState.project.constraints],
    (ids, constraints) => {
      return pipe(
        ids,
        M.toArray(Ord),
        A.map(flow(T.fst, (constraintId) => M.lookup(Eq)(constraintId)(constraints))),
        A.compact,
      )
    },
  ),
}

export default sessionModel
