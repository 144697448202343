import { Grid, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { grey } from "@mui/material/colors"
import { useStoreState } from "../../state/hooks"

const PREFIX = "Footer"

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled("footer")(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: grey[200],
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(4),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(8),
  },
}))

const Footer = () => {
  const title = useStoreState((store) => store.project.name)
  const toolVersion = useStoreState((store) => store.version)
  const projectVersion = useStoreState((store) => store.project.version)

  return (
    <Root className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">Legal text</Typography>
          <Typography variant="body1">Support text</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="body2">Tool version {toolVersion}</Typography>
          <Typography variant="body2">Rule version {projectVersion}</Typography>
        </Grid>
      </Grid>
    </Root>
  )
}

export default Footer
