import { styled } from "@mui/material/styles"
import { Card, FormControl, FormGroup, Button, Container } from "@mui/material"

import { useStoreActions, useStoreState } from "../../../state/hooks"
import { groupCrops } from "./utils"
import CropGroup from "./CropGroup"
import strings from "../../../strings"

const PREFIX = "CropSelection"

const classes = {
  root: `${PREFIX}-root`,
  formControl: `${PREFIX}-formControl`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  selectAll: `${PREFIX}-selectAll`,
  selectNone: `${PREFIX}-selectNone`,
}

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },

  [`& .${classes.formControl}`]: {},

  [`& .${classes.buttonContainer}`]: {
    display: "flex",
    marginTop: theme.spacing(1),
  },

  [`& .${classes.selectAll}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.selectNone}`]: {
    margin: theme.spacing(1),
  },
}))

const CropSelection = () => {
  const crops = useStoreState((state) => state.project.cropsAsList)

  const groupedCrops = groupCrops(crops)

  const addList = useStoreActions((actions) => actions.session.crops.addList)
  const removeList = useStoreActions((actions) => actions.session.crops.removeList)

  const selectAll = () => {
    groupedCrops.forEach((group) => {
      const cropsById = group.crops.map((crop) => crop.id)
      addList(cropsById)
    })
  }

  const selectNone = () => {
    groupedCrops.forEach((group) => {
      const cropsById = group.crops.map((crop) => crop.id)
      removeList(cropsById)
    })
  }

  return (
    <StyledCard className={classes.root} variant="outlined">
      <Container disableGutters={true} className={classes.buttonContainer}>
        <Button className={classes.selectAll} onClick={selectAll} variant="contained">
          {strings.GENERAL_ELEMENTS.SELECT_ALL_BUTTON}
        </Button>
        <Button className={classes.selectNone} onClick={selectNone} variant="contained">
          {strings.GENERAL_ELEMENTS.CLEAR_SELECT_BUTTON}
        </Button>
      </Container>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          {groupedCrops.map((group, index) => (
            <CropGroup key={index} group={group} />
          ))}
        </FormGroup>
      </FormControl>
    </StyledCard>
  )
}

export default CropSelection
