import {
  Crop,
  CropConstraint,
  CropResult,
  isCropConstraint,
  isSiteConstraint,
  Constraint,
  SiteConstraint,
  Definition,
  Project,
  ProjectFile,
  Rule,
  lookup,
} from "./types/types"

import * as A from "fp-ts/lib/Array"
import * as O from "fp-ts/lib/Option"
import JSZip from "jszip"
import { pipe } from "fp-ts/lib/function"

import { parse } from "./types/Score"
import { EnvironmentalValueGeometry, SampledData } from "./types/SampledData"

const isDevelopment: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
// const blobAddress: string = process.env.REACT_APP_BLOB_STORAGE || ""
const attributeServiceAddress: string = process.env.REACT_APP_ATTRIBUTE_SERVICE || ""

export const wait = <T>(ms: number, value: T): Promise<T> => {
  return new Promise((resolve) => setTimeout(resolve, ms, value))
}

export const fetchProject = async (): Promise<Project> => {
  const URL = isDevelopment ? "http://localhost:8080/lscd_latest.zip" : `/projects/lscd_latest.zip`

  const response = await fetch(URL)

  if (response.status === 200 || response.status === 0) {
    const blob = await response.blob()
    const arrayBuffer = await blob.arrayBuffer()
    return await importProjectZip(arrayBuffer)
  } else {
    throw new Error(response.statusText)
  }
}

type AttributeResult = Record<string, AttributeValue[]>

type AttributeValue = {
  x: number
  y: number
  value: string
  geom?: Polygon
}

export type GeometryIn = Point | Polygon

type Point = {
  type: "Point"
  coordinates: [number, number]
}

type Polygon = {
  type: "Polygon"
  coordinates: [number, number][][]
}

export const fetchAttributes = async (feature: GeometryIn, srid: number): Promise<AttributeResult> => {
  const URL = isDevelopment ? "http://localhost:8000/attributes" : `${attributeServiceAddress}/attributes`
  const response = await fetch(URL, {
    method: "POST",
    body: JSON.stringify({ geom_json: feature, srid }),
    headers: { "Content-Type": "application/json" },
  })

  if (!response.ok) {
    throw new Error("Network response was not ok")
  }

  return response.json()
}

export const fetchSampledPolygon = async (feature: GeometryIn, srid: number): Promise<SampledData> => {
  const URL = isDevelopment ? "http://localhost:8000/attribute/polygon" : `${attributeServiceAddress}/attribute/polygon`
  const response = await fetch(URL, {
    method: "POST",
    body: JSON.stringify({ geometry: { geom_json: feature, srid } }),
    headers: { "Content-Type": "application/json" },
  })

  if (!response.ok) {
    throw new Error("Network response was not ok")
  }

  return response.json()
}

export const fetchGBRRegions = async (): Promise<string> => {
  const URL = isDevelopment ? "http://localhost:8080/gbr.json" : `/projects/gbr.json`

  const response = await fetch(URL)

  if (!response.ok) {
    throw new Error("Network response was not ok")
  }

  return response.text()
}

export const fetchEnvironValueGeometry = async (
  feature: GeometryIn,
  srid: number,
): Promise<EnvironmentalValueGeometry[]> => {
  const URL = isDevelopment
    ? "http://localhost:8000/environmental_values"
    : `${attributeServiceAddress}/environmental_values`

  const response = await fetch(URL, {
    method: "POST",
    body: JSON.stringify({ geom_json: feature, srid }),
    headers: { "Content-Type": "application/json" },
  })

  if (!response.ok) {
    throw new Error("Network response was not ok")
  }

  return response.json()
}

export const getConstraints = (constraints: Constraint[]): Constraint[] => {
  const filteredConstraints = constraints.filter((c) => !isCropConstraint(c))

  return filteredConstraints
}

export const getCrops = (constraints: Constraint[]): CropConstraint | undefined => {
  const cropConstraint = constraints.find(isCropConstraint)

  return cropConstraint
}

export const getSites = (constraints: Constraint[]): SiteConstraint | undefined => {
  const siteConstraint = constraints.find(isSiteConstraint)

  return siteConstraint
}

export const calculateCropLimitations = (
  rules: Rule[],
  constraintValues: Map<string, string>,
  crop: Crop,
): CropResult => {
  const factStore = constraintValues.set("crop", crop.id)
  const results = pipe(
    rules,
    A.map((rule) =>
      pipe(
        lookup(factStore, rule),
        O.map(parse),
        O.compact,
        O.map((score) => ({ rule, score })),
      ),
    ),
    A.compact,
  )

  return { crop, results }
}

export const calculateResult = async (
  rules: Rule[],
  constraintValues: Map<string, string>,
  crops: Crop[],
): Promise<CropResult[]> => {
  return crops.map((crop) => calculateCropLimitations(rules, constraintValues, crop))
}

export const importProjectZip = async (blob: ArrayBuffer): Promise<Project> => {
  const zip = await JSZip.loadAsync(blob)

  if (zip) {
    // Parse the project json file
    const projectFile = await zip.file("project.json")

    if (projectFile) {
      const projectData = await projectFile.async("string")

      const project: ProjectFile = readProjectJson(projectData)

      // Parse the csv files
      const ruleFiles = project.rules.map(async (rule) => {
        // Get the file from the zip
        const file = zip.file(rule.definitionFile)

        if (file === null) throw new Error("File does not exist: " + rule.definitionFile)

        const data = await file.async("string")

        return [rule.id, readRuleDefinitionJson(data)] as [string, Definition[]]
      })

      let ruleDefinitions: Record<string, Definition[]> = {}

      await Promise.all(ruleFiles).then((results) =>
        results.map(([ruleId, ruleDefinition]) => (ruleDefinitions[ruleId] = ruleDefinition)),
      )

      const rules: Rule[] = project.rules.map((rule) => ({
        ...rule,
        definition: ruleDefinitions[rule.id],
      }))

      return new Promise((resolve) => resolve({ ...project, rules }))
    }
  }
  return new Promise((resolve, reject) => reject())
}

const readProjectJson = (projectData: string): ProjectFile => {
  const projectJson = JSON.parse(projectData)
  return projectJson as ProjectFile
}

const readRuleDefinitionJson = (ruleDefinitionData: string): Definition[] => {
  const ruleDefinitionJson = JSON.parse(ruleDefinitionData)
  return ruleDefinitionJson as Definition[]
}
