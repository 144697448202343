import { action, Action } from "easy-peasy"

export type SelectedRulesModel = {
  // State
  selectedRules: Set<string>

  // Actions
  add: Action<SelectedRulesModel, { id: string }>
  remove: Action<SelectedRulesModel, { id: string }>
}

const rulesModel: SelectedRulesModel = {
  selectedRules: new Set(),
  add: action((state, payload) => {
    state.selectedRules.add(payload.id)
  }),
  remove: action((state, payload) => {
    state.selectedRules.delete(payload.id)
  }),
}

export default rulesModel
