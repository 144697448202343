import React from "react"

import { Box, Typography } from "@mui/material"

import MapSelect from "./MapSelect"
import strings from "../../../strings"

const RegionSelect = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography component="h2" variant="h6">
          {strings.REGION_STEP.PAGE_TITLE}
        </Typography>
      </Box>

      <Box paddingX={1} paddingY={1}>
        <MapSelect />
      </Box>
    </>
  )
}

export default React.memo(RegionSelect)
