/**
 * Computes the mean (average) value in an array.
 * @param array observations
 * @returns the arithmetic mean
 */
export const mean = (array: readonly number[]): number => {
  let sum = 0
  for (let i = 0; i < array.length; i++) {
    sum += array[i]
  }
  return sum / array.length
}

/**
 * Computes the median value in an array.
 * @param array observations
 * @returns the arithmetic mean
 */
export const median = (array: readonly number[]): number => {
  const sortedArray = [...array].sort((a, b) => a - b)
  const middle = Math.floor(array.length / 2)

  if (array.length % 2 === 0) {
    return (sortedArray[middle - 1] + sortedArray[middle]) / 2
  } else {
    return sortedArray[middle]
  }
}

/**
 * Computes the most common value/s in an array (mode)
 * @param array observations
 * @returns the most frequent value/s in an array, and its frequency
 */
export const mode = (array: readonly string[]): [string[], number] => {
  const count: Record<string, number> = {}

  let mode: string[] = []
  let max = 0

  for (let i = 0; i < array.length; i++) {
    if (!(array[i] in count)) count[array[i]] = 0

    count[array[i]] += 1

    if (count[array[i]] === max) {
      mode.push(array[i])
    } else if (count[array[i]] > max) {
      max = count[array[i]]
      mode = [array[i]]
    }
  }

  return [mode, max]
}

/**
 * Computes the sample variance
 * @param array observations
 * @returns the sample variance
 */
export const variance = (array: readonly number[]): number => {
  const arrayMean = mean(array)

  let sum = 0
  for (let i = 0; i < array.length; i++) {
    const diff = array[i] - arrayMean
    sum += diff * diff
  }

  return sum / (array.length - 1)
}

/**
 * Computes the sample standard deviation
 * @param array observations
 * @returns the sample standard deviation
 */
export const std = (array: readonly number[]): number => {
  const arrayVariance = variance(array)
  return Math.sqrt(arrayVariance)
}

/**
 * Computes the coefficient of variation, the ratio of the standard deviation to the mean
 * @param array observations
 */
export const cvMean = (array: readonly number[]): number => {
  const arrayStd = std(array)
  const arrayMean = mean(array)

  return arrayStd / arrayMean
}

/**
 * Computes the range between the 25% and 75% quartiles
 * @param array observations
 */
export const varMedian = (array: readonly number[]): number => {
  const sortedArray = [...array].sort((a, b) => a - b)
  const middle = Math.floor(array.length / 2)
  const firstHalf = sortedArray.splice(0, middle)

  let secondHalf = []
  if (array.length % 2 === 0) {
    secondHalf = sortedArray
  } else {
    sortedArray.splice(0, 1)
    secondHalf = sortedArray
  }

  return median(secondHalf) - median(firstHalf)
}

/**
 * Computes variance with p = (mode / n)
 * @param array observations
 */
export const varMode = (array: readonly string[]): number => {
  const count = array.length
  const [, modeMax] = mode(array)

  // n * p * (1 - p)
  // where n: count
  //       p: modeMax / count
  //       q: (1 - modeMax / count) 
  return modeMax * (1 - modeMax / count)
}

/**
 * Computes p * q using p = (mode / n).
 * @param array observations
 */
export const pqMode = (array: readonly string[]): number => {
  const count = array.length
  const [, modeMax] = mode(array)

  return modeMax / count * (1 - modeMax / count)
}
