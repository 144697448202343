import alertModel, { AlertModel } from "./alert"
import projectModel, { ProjectModel } from "./project"
import sessionModel, { SessionModel } from "./session/session"
import workersModel, { WorkersModel } from "./workers"

export type StoreModel = {
  // State
  version: string
  project: ProjectModel
  session: SessionModel
  alert: AlertModel
  workers: WorkersModel
}

const storeModel: StoreModel = {
  version: "1.0.0",
  project: projectModel,
  session: sessionModel,
  alert: alertModel,
  workers: workersModel,
}

export default storeModel
