import { action, Action, computed, Computed, thunkOn, ThunkOn } from "easy-peasy"
import { StoreModel } from "."
import { DataCollection } from "../../types/DataCollection"

export type Alert = {
  message: string
  removeable: boolean
  severity: AlertSeverity
  section: AlertSection
  id: number
  description?: AlertDescription
  constraintIds?: string[] // for high variability alerts, this should be the id of the constraint with high variability.
}

function generateHighVarMessage(constraintNames : string[]) {
  return "Results for the area selected are too variable. Please select a smaller area. (" +
  constraintNames.join(", ") +
  ")"
}

export type AlertCreate = Omit<Alert, "id">

export type AlertSection = "map" | "autofill" | "report"
export type AlertSeverity = "error" | "warning" | "info" | "success"
export type AlertDescription = "high variability"

export type AlertModel = {
  nextId: 0
  alerts: Alert[]
  mapAlerts: Computed<AlertModel, Alert[]>
  autofillAlerts: Computed<AlertModel, Alert[]>
  reportAlerts: Computed<AlertModel, Alert[]>

  addAlert: Action<AlertModel, AlertCreate>
  removeAlert: Action<AlertModel, number>

  clearAlerts: Action<AlertModel>
  clearAlertsBySection: Action<AlertModel, AlertSection>

  onFetchAttributes: ThunkOn<AlertModel, {}, StoreModel>
  onUserAttributeEntry: ThunkOn<AlertModel, {}, StoreModel>
}

const alertModel: AlertModel = {
  nextId: 0,
  alerts: [],
  mapAlerts: computed((state) => state.alerts.filter((alert) => alert.section === "map")),
  autofillAlerts: computed((state) => state.alerts.filter((alert) => alert.section === "autofill")),
  reportAlerts: computed((state) => state.alerts.filter((alert) => alert.section === "report")),
  addAlert: action((state, payload) => {
    state.alerts.push({ ...payload, id: state.nextId++ })
  }),
  removeAlert: action((state, payload) => {
    state.alerts = state.alerts.filter((alert) => alert.id !== payload)
  }),
  clearAlerts: action((state) => {
    state.alerts = []
  }),
  clearAlertsBySection: action((state, payload) => {
    state.alerts = state.alerts.filter((alert) => alert.section !== payload)
  }),

  onFetchAttributes: thunkOn(
    (_, storeActions) => storeActions.session.constraints.updateServerDataByConstraint,
    (actions, payload) => {
      const constraintDataCollections = payload.payload
      const tooVariable : DataCollection[] = []
      constraintDataCollections.forEach((dc) => {
        if (dc.stats?.variability === "high") tooVariable.push(dc)
      })

      if (tooVariable.length > 0) {
        actions.addAlert({
          message: generateHighVarMessage(tooVariable.map((dc) => dc.constraint.name)),
          removeable: true,
          severity: "error",
          section: "autofill",
          description: "high variability",
          constraintIds: tooVariable.map((dc) => dc.constraint.id)
        })
      }
    },
  ),

  onUserAttributeEntry: thunkOn(
    (_, storeActions) => storeActions.session.constraints.updateUser,
    (actions, target, { getStoreState }) => {

      // Remove all high-variability alerts that are related to the changed constraint
      getStoreState().alert.autofillAlerts
        .filter((alert) => alert?.description === "high variability" )
        .filter((alert) => (alert?.constraintIds ?? []).includes(target.payload.id))
        .forEach((alert) => {
          actions.removeAlert(alert.id)

          // If the alert has more than one constraint, add it back with the changed constraint removed.
          if ((alert.constraintIds?.length ?? []) > 1) {
            const ids = (alert?.constraintIds ?? []).filter((id) => id !== target.payload.id)
            const constraints = getStoreState().project.constraints

            const names = ids
              .map((id) => constraints.get(id)?.name)
              .filter((name) => name !== undefined) as string[] // cast because typescript doesn't believe it's hole-free

            actions.addAlert({...alert, constraintIds: ids, message: generateHighVarMessage(names)})
          }
        })

      // Now what needs to be done is go through all active constraints.
      // To the same as onFetch, but ignore attributes where user entered data.
    },
  ),
}

export default alertModel
