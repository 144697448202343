import { useEffect } from "react"

import { styled } from "@mui/material/styles"

import { Grid, Typography } from "@mui/material"

import { useStoreActions, useStoreState } from "../../../state/hooks"
import SelectedLimitations from "./SelectedLimitations"
import AddLimitations from "./AddLimitations"
import strings from "../../../strings"

const PREFIX = "LimitationSection"

const classes = {
  list: `${PREFIX}-list`,
  grid: `${PREFIX}-grid`,
}

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.list}`]: {
    width: 350,
    height: 400,
    overflow: "auto",
  },

  [`& .${classes.grid}`]: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
}))

// TODO: Read defaults from project definition file
const DEFAULT_RULES = new Set(["E", "ES", "NL"])

const LimitationSection = () => {
  const rules = useStoreState((state) => state.project.rules)

  const add = useStoreActions((state) => state.session.rules.add)

  // On component mount, attempt to add the items in DEFAULT_RULES
  useEffect(() => {
    DEFAULT_RULES.forEach((rule) => {
      if (rules.has(rule)) add({ id: rule })
    })
  }, [add, rules])

  return (
    <Root>
      <Typography component="h2" variant="h6">
        {strings.ATTRIBUTES_STEP.LIMITATIONS_TITLE}
      </Typography>
      <Typography variant="body2">{strings.ATTRIBUTES_STEP.LIMITATIONS_SUBTITLE}</Typography>
      <Grid className={classes.grid} container justifyContent="space-around" alignItems="center">
        <Grid item>
          <AddLimitations />
        </Grid>
        <Grid item>
          <SelectedLimitations unremoveableRules={DEFAULT_RULES} />
        </Grid>
      </Grid>
    </Root>
  )
}

export default LimitationSection
