import { Typography } from "@mui/material"
import CropSelection from "./CropSelection"
import strings from "../../../strings"

const CropSection = () => {
  return (
    <>
      <Typography component="h2" variant="h6">
        {strings.CROPS_STEP.PAGE_TITLE}
      </Typography>
      <CropSelection />
    </>
  )
}

export default CropSection
