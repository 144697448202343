import React, { useState } from "react"

import { styled } from "@mui/material/styles"

import { AppBar, Button, Toolbar, Typography } from "@mui/material"
import { useStoreState } from "../../state/hooks"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import Drawer from "@mui/material/Drawer"
import GlossaryList from "./GlossaryList"
import Divider from "@mui/material/Divider"
import CloseIcon from '@mui/icons-material/Close';
import strings from "../../strings"

const PREFIX = "TitleBar"

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  title: `${PREFIX}-title`,
  drawer: `${PREFIX}-drawer`,
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`& .${classes.toolbar}`]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
  },
}))

const TitleBar = () => {
  const title = useStoreState((store) => store.project.name)
  const [open, setOpen] = useState(false)

  return (
    <StyledAppBar position="static">
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <Button startIcon={<ArrowBackIcon />} onClick={() => setOpen(true)} color="inherit">
          {strings.GENERAL_ELEMENTS.GLOSSARY_TEXT}
        </Button>
        <Drawer className={classes.drawer} anchor={"right"} open={open} onClose={() => setOpen(false)}>
          <Button
            sx={{ margin: 1 }} //not sure why the usual method of giving this a class and styling that class didn't work.
            startIcon={<CloseIcon />}
            onClick={() => setOpen(false)}
            size="large"
            fullWidth={false}
          >
            {strings.GENERAL_ELEMENTS.GLOSSARY_CLOSE_TEXT}
          </Button>
          <Divider />
          <GlossaryList />
        </Drawer>
      </Toolbar>
    </StyledAppBar>
  )
}

export default React.memo(TitleBar)
