import { useState } from "react"
import { styled } from "@mui/material/styles"
import { Box, Button, Card, Divider, List, ListItem, ListItemText, Typography } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { useStoreActions, useStoreState } from "../../../state/hooks"
import { useIsFetching } from "react-query"
import SearchInput from "../../common/SearchInput"

const PREFIX = "AddLimitations"

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  list: `${PREFIX}-list`,
  searchContainer: `${PREFIX}-searchContainer`,
}

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 400,
  },

  [`& .${classes.header}`]: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  [`& .${classes.list}`]: {
    width: 350,
    height: 400,
    overflow: "auto",
  },

  [`& .${classes.searchContainer}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}))

type AddListItemProps = {
  ruleCode: string
  disabled: boolean
}

export const AddListItem = (props: AddListItemProps) => {
  const { ruleCode } = props
  const name = useStoreState((state) => state.project.rules.get(ruleCode)?.name ?? "")
  const add = useStoreActions((state) => state.session.rules.add)

  const handleAdd = () => add({ id: ruleCode })

  const isFetching = useIsFetching()

  const labelId = `transfer-list-item-${ruleCode}-label`

  return (
    <ListItem button onClick={handleAdd} disabled={isFetching > 0 || props.disabled}>
      <ListItemText id={labelId} primary={name} />
      <AddIcon />
    </ListItem>
  )
}

type Props = {}

const AddLimitations = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState("")

  const rules = useStoreState((state) => state.project.rules)
  const ruleCodes = useStoreState((state) => state.project.rulesAsList)
  const selectedRules = useStoreState((state) => state.session.rules.selectedRules)
  const add = useStoreActions((state) => state.session.rules.add)

  const notSelected = ruleCodes.filter((rule) => !selectedRules.has(rule))
  const disabled = false

  const handleAddAll = () => notSelected.forEach((id) => add({ id: id }))

  const filtered = notSelected.filter((code) => {
    const rule = rules.get(code)

    if (rule === undefined) return false
    if (searchTerm.length === 0) return true

    const { name, id, description } = rule
    const searchDomain = [name, id, description ?? ""]

    const searchTermLowered = searchTerm.toLowerCase()
    return searchDomain.filter((item) => item.toLowerCase().includes(searchTermLowered)).length > 0
  })

  return (
    <StyledCard className="root" variant="outlined">
      <Box className={classes.header}>
        <Typography display="inline" variant="subtitle1">
          Available Limitations
        </Typography>

        <Button onClick={handleAddAll}>Select All</Button>
      </Box>

      <Box className={classes.searchContainer}>
        <SearchInput value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
      </Box>
      {/* Disabled due to not being used */}
      {/* <Button
        onClick={() => {
          setDisabled(!disabled)
        }}
      >
        {(disabled ? "Enable" : "Disable") + " limitation selection"}
      </Button> */}
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {filtered.map((ruleCode, idx) => (
          <AddListItem key={idx} ruleCode={ruleCode} disabled={disabled} />
        ))}
      </List>
    </StyledCard>
  )
}

export default AddLimitations
