import { useState } from "react"
import { styled } from "@mui/material/styles"
import { Checkbox, Collapse, FormControlLabel, FormGroup } from "@mui/material"
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import { useStoreActions, useStoreState } from "../../../state/hooks"
import CropItem from "./CropItem"
import { CropsByGroup } from "./utils"

const PREFIX = "CropGroup"

const classes = {
  controlGroup: `${PREFIX}-controlGroup`,
  controlIndented: `${PREFIX}-controlIdented`,
  collapseIcon: `${PREFIX}-collapseIcon`,
}

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.controlGroup}`]: {
    alignItems: "center",
  },

  [`& .${classes.controlIndented}`]: {
    margin: 0,
    paddingLeft: theme.spacing(8),
  },

  [`& .${classes.collapseIcon}`]: {
    marginRight: theme.spacing(1),
  },
}))

type Props = {
  group: CropsByGroup
}

const CropGroup = (props: Props) => {
  const { group } = props

  const [collapsed, setCollapsed] = useState(false)

  const addList = useStoreActions((actions) => actions.session.crops.addList)
  const removeList = useStoreActions((actions) => actions.session.crops.removeList)

  const selectedCrops = useStoreState((state) => state.session.crops.crops)
  const groupSelectedCrops = group.crops.filter((crop) => selectedCrops.has(crop.id))
  const allChecked = groupSelectedCrops.length === group.crops.length
  const someChecked = groupSelectedCrops.length > 0

  const toggleCollapse = () => setCollapsed((collapsed) => !collapsed)

  const checkGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cropsById = group.crops.map((crop) => crop.id)
    if (e.target.checked) {
      addList(cropsById)
    } else {
      removeList(cropsById)
    }
  }

  return (
    <Root>
      <FormGroup row classes={{ row: classes.controlGroup }}>
        {collapsed ? (
          <ArrowDropUp className={classes.collapseIcon} onClick={toggleCollapse} />
        ) : (
          <ArrowDropDown className={classes.collapseIcon} onClick={toggleCollapse} />
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={allChecked}
              onChange={checkGroup}
              name={group.groupName}
              indeterminate={!allChecked && someChecked}
            />
          }
          label={group.groupName}
        />
      </FormGroup>
      <Collapse in={collapsed}>
        <FormGroup className={classes.controlIndented}>
          {group.crops.map((crop, idx) => (
            <CropItem key={idx} crop={crop} />
          ))}
        </FormGroup>
      </Collapse>
    </Root>
  )
}

export default CropGroup
