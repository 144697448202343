import { useRef, useState } from "react"
import { styled } from "@mui/material/styles"
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material"
import { ArrowDropDown } from "@mui/icons-material"
const PREFIX = "LayerButton"

const classes = {
  button: `${PREFIX}-button`,
  buttonGroup: `${PREFIX}-buttonGroup`,
}

const Root = styled("div")(() => ({
  [`& .${classes.button}`]: {
    borderColor: "rgba(0,60,136,0.7)",
    backgroundColor: "rgba(0,60,136,0.7)",
    "&:hover": {
      backgroundColor: "rgba(0,60,136,0.9)",
    },
  },

  [`& .${classes.buttonGroup}`]: {
    "&:not(:last-child)": {
      borderColor: "rgba(255,255,255,0.4)",
    },
  },
}))

const options = ["Map", "Satellite"]

type Props = {
  onSelectCallback: (activeLayer: string) => void
}

const LayerButton = (props: Props) => {
  const { onSelectCallback } = props

  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(1)
  const anchorRef = useRef<HTMLDivElement>(null)

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index)
    onSelectCallback(options[index])
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((open) => !open)
  }

  const handleCycle = () => {
    setSelectedIndex((index) => {
      const newIndex = (index + 1) % options.length
      onSelectCallback(options[newIndex])
      return newIndex
    })
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <Root>
      <ButtonGroup
        classes={{ groupedContainedPrimary: classes.buttonGroup }}
        variant="contained"
        color="primary"
        ref={anchorRef}
        disableElevation={true}
        size="small"
      >
        <Button className={classes.button} onClick={handleCycle}>
          {/*The button's text should be the next option*/}
          {options[(selectedIndex + 1) % options.length]} view
        </Button>
        <Button className={classes.button}>
          <ArrowDropDown fontSize="small" onClick={handleToggle} />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "right top" : "right bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      dense
                      key={option}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Root>
  )
}

export default LayerButton
