import React from "react"
import { styled } from "@mui/material/styles"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { Link } from "@mui/material"
import strings from "../strings"

const PREFIX = "DisclaimerDialog"

const classes = {
  paddedText: `${PREFIX}-paddedText`,
  disagreeButton: `${PREFIX}-disagreeButton`,
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paddedText}`]: {
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.disagreeButton}`]: {
    color: "#f50057", // Manual override to keep the colour the same as before.
  },
}))

export default function DisclaimerDialog() {
  const [open, setOpen] = React.useState(true)

  const handleAgree = () => {
    setOpen(false)
  }

  return (
    <StyledDialog
      open={open}
      aria-labelledby="disclaimer-dialog-title"
      aria-describedby="disclaimer-dialog-description"
    >
      <DialogTitle id="disclaimer-dialog-title">{strings.DISCLAIMERS.DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText id="disclaimer-dialog-description" variant="body2" className={classes.paddedText}>
          {strings.DISCLAIMERS.DIALOG_SECTION_1}
        </DialogContentText>
        <DialogContentText id="disclaimer-dialog-description" variant="body2" className={classes.paddedText}>
          {strings.DISCLAIMERS.DIALOG_SECTION_2}
        </DialogContentText>
        <DialogContentText id="disclaimer-dialog-description" variant="body2" className={classes.paddedText}>
          {strings.DISCLAIMERS.DIALOG_SECTION_3}
          <a href={strings.DISCLAIMERS.DIALOG_LINK}>{strings.DISCLAIMERS.DIALOG_LINK}</a>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="info" className={classes.disagreeButton}>
          <Link
            aria-label="Redirect to qld.gov.au"
            color="inherit"
            href={strings.DISCLAIMERS.DISAGREE_LINK}
            underline="hover"
          >
            {strings.DISCLAIMERS.DISAGREE_BUTTON}
          </Link>
        </Button>
        <Button onClick={handleAgree} color="primary">
          {strings.DISCLAIMERS.AGREE_BUTTON}
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}
